import {
  Role,
  MemberEmailStatus,
  RoleType,
  NetworkStatus,
} from '@tribeplatform/gql-client/types'

export const isUnconfirmedMember = (member, role?: Role): boolean =>
  !!member &&
  member.emailStatus !== MemberEmailStatus.verified &&
  role?.type === RoleType.guest

const NO_AUTH_PATHS = ['/oauth/', '/auth/', '/api/analytics', '/ssos/redirect']
export const isNoAuthPath = (path: string): boolean => {
  return NO_AUTH_PATHS.some(noAuthPath => path.startsWith(noAuthPath))
}

export const pathMayRequireAuthorization = (path: string): boolean => {
  return !isNoAuthPath(path)
}

export const isRestricted = (
  roleType: RoleType,
  networkStatus: NetworkStatus,
  url: string,
): boolean => {
  const isAdmin = roleType === RoleType.admin
  const isUnpublished = networkStatus === NetworkStatus.unpublished
  const isArchived = networkStatus === NetworkStatus.archived

  if (isArchived && !url.startsWith('/auth/restricted')) {
    return true
  }

  // For admins everything is allowed.
  // For guests and regular members, only login page is allowed.
  const isURLAllowed =
    isAdmin ||
    url.startsWith('/auth/login') ||
    url.startsWith('/auth/restricted')

  return isUnpublished && !isURLAllowed
}

type TGenerateLoginUrl = (opts: {
  redirect?: string
  disableAutoLogin?: boolean
}) => string

export const generateLoginUrl: TGenerateLoginUrl = ({
  redirect,
  disableAutoLogin,
}) => {
  const params = new URLSearchParams()
  if (redirect) {
    params.append('redirect', redirect)
  }

  if (disableAutoLogin) {
    params.append('auto_login', 'false')
  }
  const query = params.toString()
  return `/auth/login${query.length ? `?${query}` : ''}`
}

export const generateMemberVerificationUrl = (
  memberId: string,
  redirect?: string,
): string => {
  const params = new URLSearchParams({
    memberId,
  })
  if (redirect) {
    params.append('redirect', redirect)
  }

  return `/auth/verify?${params.toString()}`
}
