import { AuthToken } from '@tribeplatform/gql-client/types'

import { AppInitialProps } from '../@types/index.js'
import { RuntimeConfigs } from '../configs/runtime.js'
import { APP_DATA_KEY } from './constants/app.constants.js'

let _initialProps: AppInitialProps
export const getInjectedAppProps = (): AppInitialProps => {
  if (_initialProps) {
    return _initialProps
  }
  try {
    _initialProps = window[APP_DATA_KEY] ?? {}
    const appDataScript = document.getElementById(APP_DATA_KEY)
    if (appDataScript) {
      appDataScript.remove()
    }
    return _initialProps
  } catch (e) {
    throw new Error('Missing initial app data')
  }
}

const getAuthTokenFromDocument = (): AuthToken => {
  const { authToken } = getInjectedAppProps()
  return authToken
}

const getAuthTokenFromRemote = async (domain: string): Promise<AuthToken> => {
  const {
    networkPath: { subfolder },
  } = getInjectedAppProps()
  const networkDomain =
    RuntimeConfigs.FORCED_NETWORK_DOMAIN || domain || window.location.hostname
  const { default: axios } = await import('axios')
  const response = await axios.post<AuthToken>(
    `${subfolder || ''}/api/auth/refresh-token`,
    {
      networkDomain,
    },
  )

  if (!response?.data) {
    throw new Error('Could not fetch the auth token')
  }

  const authToken = response.data
  return authToken
}

export const getAuthToken = async (domain: string): Promise<AuthToken> => {
  if (domain) {
    return getAuthTokenFromRemote(domain)
  }
  return getAuthTokenFromDocument()
}
