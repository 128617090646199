import { useCallback } from 'react'

import { RoleType } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import {
  useAuthToken,
  SystemSpaceSlug,
  generatePath,
} from '@tribeplatform/react-sdk/hooks'

export const useLogin = () => {
  const {
    data: { network },
  } = useAuthToken()
  const systemSpaces = network?.systemSpaces
  const { data: authToken } = useAuthToken()
  const isGuest =
    !authToken?.role?.type || authToken?.role?.type === RoleType.guest
  const { push, replace } = useRouter()

  const showLogin = useCallback(
    (replacePath = false) => {
      if (!isGuest) {
        return
      }

      const path = generatePath(
        systemSpaces,
        SystemSpaceSlug.LOGIN,
        null,
        window.location?.pathname
          ? { redirect: encodeURIComponent(window.location.pathname) }
          : null,
      )

      if (replacePath) {
        replace(path)
      } else {
        push(path)
      }
    },
    [isGuest, push, replace, systemSpaces],
  )

  const isLoggedIn = !isGuest

  return {
    showLogin,
    isLoggedIn,
  }
}
