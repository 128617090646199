import './styles.css'
import { lazy, Suspense } from 'react'

import { HelmetProvider } from 'react-helmet-async'

import { GraphqlClient } from '@tribeplatform/gql-client/client'
import type { ClientError, ErrorResponse } from '@tribeplatform/gql-client/lib'
import { CommandBarProvider } from '@tribeplatform/react-components/CommandBar'
import { FirstRenderProvider } from '@tribeplatform/react-components/common/ssr'
import { I18nProvider } from '@tribeplatform/react-components/i18n'
import { QueryLayoutProvider } from '@tribeplatform/react-components/LayoutFrame'
import { ModalProvider } from '@tribeplatform/react-components/Modal'
import { Hydrate, Provider } from '@tribeplatform/react-sdk'
import { getNetworkKey } from '@tribeplatform/react-sdk/utils/keys'
import { AppProvider } from '@tribeplatform/react-ui-kit/AppProvider'

import { AppInitialProps } from '../@types/index.js'
import { RuntimeConfigs } from '../configs/runtime.js'
import { getAssetPath } from '../configs/static.js'
import { AppRoutes } from './AppRoutes.js'
import { CacheControl } from './CacheControl.js'
import { useTracker } from './hooks/useTracker.js'
import { useInteractions, interactionEmitter } from './interactions/index.js'
import {
  TribeEmitter,
  TribeEmitterEvent,
} from './lib/tribe-events/TribeEmitter.js'
import { WebThemeProvider } from './new-themes/WebThemeProvider.js'
import { IntercomBootProvider } from './providers/IntercomBootProvider.js'
import { DesktopNotificationProvider } from './providers/notification/index.js'
import { Optimizely } from './providers/optimizely/Provider.js'
import { TribeWeb } from './TribeWeb.js'
import { onClientError, errorBoundaryHandler } from './utils/onError.js'

const CommandBar = lazy(() =>
  import('@tribeplatform/react-components/CommandBar').then(m => ({
    default: m.CommandBar,
  })),
)

const EmojiLoader = lazy(() =>
  import('./components/EmojiLoader.js').then(m => ({
    default: m.EmojiLoader,
  })),
)

type AppProps = {
  pageProps: AppInitialProps
  apiUrl: string
  betaApiUrl: string
}

export const App = ({ pageProps, apiUrl, betaApiUrl }: AppProps) => {
  const tracker = useTracker({
    member: pageProps.authToken?.member,
    network: pageProps.authToken?.network,
  })

  const { intercom } = pageProps

  return (
    <I18nProvider i18n={pageProps.i18n}>
      <Provider
        config={{
          baseUrl: apiUrl,
          betaBaseUrl: betaApiUrl,
          shouldUseBetaClient: pageProps.useBetaClient ?? false,
          gatewayCacheControl: pageProps.bypassCache ? 'no-cache' : undefined,
          networkDomain: pageProps.authToken?.network?.domain,
          accessToken: pageProps.authToken?.accessToken,
          giphyApiKey: RuntimeConfigs.GIPHY_API_KEY,
          unsplashApiKey: RuntimeConfigs.UNSPLASH_ACCESS_KEY,
          supportCommunityURL: RuntimeConfigs.SUPPORT_COMMUNITY_URL,
          getAssetPath,
          interactionsContext: {
            useInteractions,
            interactionEmitter,
          },
          onError: (
            errors: ErrorResponse[] | undefined | null,
            client: GraphqlClient,
            error?: ClientError,
          ) => {
            if (!errors) {
              return
            }
            const nonNetworkRelatedErrors = errors
              .filter(
                _error => typeof _error !== 'undefined' && _error !== null,
              )
              .filter(_error => !_error.code)
            onClientError(nonNetworkRelatedErrors, client, pageProps, error)
          },
          onEvent: event => {
            const networkQueryHash = JSON.stringify(
              getNetworkKey({ fields: 'default' }),
            )
            const networkCachedQuery = pageProps.dehydratedState.queries.find(
              query => JSON.stringify(query.queryKey) === networkQueryHash,
            )
            const {
              state: { data: network },
            } = networkCachedQuery

            TribeEmitter.emit(TribeEmitterEvent.Event, { ...event, network })
          },
          errorBoundary: {
            handler: errorBoundaryHandler,
          },
          tracker,
        }}
      >
        {pageProps.bypassCache && (
          <CacheControl baseUrl={pageProps.networkPath.basename} />
        )}
        <HelmetProvider>
          <Hydrate state={pageProps.dehydratedState}>
            <IntercomBootProvider
              enabled={intercom.enabled}
              appId={intercom.appId}
              bootProps={intercom.bootProps}
            >
              <Optimizely datafile={pageProps.optimizelyDataFile}>
                <Suspense fallback={<></>}>
                  <EmojiLoader />
                </Suspense>
                <WebThemeProvider pageProps={pageProps}>
                  <AppProvider>
                    <TribeWeb pageProps={pageProps}>
                      <QueryLayoutProvider>
                        <FirstRenderProvider>
                          <ModalProvider>
                            <CommandBarProvider>
                              <Suspense fallback={<></>}>
                                <CommandBar />
                              </Suspense>
                              <DesktopNotificationProvider>
                                <AppRoutes pageProps={pageProps} />
                              </DesktopNotificationProvider>
                            </CommandBarProvider>
                          </ModalProvider>
                        </FirstRenderProvider>
                      </QueryLayoutProvider>
                    </TribeWeb>
                  </AppProvider>
                </WebThemeProvider>
              </Optimizely>
            </IntercomBootProvider>
          </Hydrate>
        </HelmetProvider>
      </Provider>
    </I18nProvider>
  )
}
