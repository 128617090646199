import { lazy } from 'react'

import { Route, Routes } from 'react-router'

import { Network } from '@tribeplatform/gql-client/types'
import { useTimeFrame } from '@tribeplatform/react-components/Analytics/hooks'

const MessagesAnalytics = lazy(() =>
  import('./NetworkAnalytics/MessagesAnalytics.js').then(m => ({
    default: m.MessagesAnalytics,
  })),
)
const OverviewAnalytics = lazy(() =>
  import('./NetworkAnalytics/OverviewAnalytics.js').then(m => ({
    default: m.OverviewAnalytics,
  })),
)
const PeopleAnalytics = lazy(() =>
  import('./NetworkAnalytics/PeopleAnalytics.js').then(m => ({
    default: m.PeopleAnalytics,
  })),
)
const PostsAnalytics = lazy(() =>
  import('./NetworkAnalytics/PostsAnalytics.js').then(m => ({
    default: m.PostsAnalytics,
  })),
)
const ReachAnalytics = lazy(() =>
  import('./NetworkAnalytics/ReachAnalytics.js').then(m => ({
    default: m.ReachAnalytics,
  })),
)
const SpacesAnalytics = lazy(() =>
  import('./NetworkAnalytics/SpacesAnalytics.js').then(m => ({
    default: m.SpacesAnalytics,
  })),
)
const AuditLogs = lazy(() =>
  import('../Admin/AuditLogs/index.js').then(m => ({
    default: m.AuditLogs,
  })),
)

export const Analytics = ({ network }: { network: Network }) => {
  const { createdAt } = network
  const { timeFrame, TimeFrameSelect } = useTimeFrame({
    createdAt,
  })

  return (
    <Routes>
      <Route
        path="analytics"
        element={
          <OverviewAnalytics
            network={network}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route
        path="analytics/dashboard"
        element={
          <OverviewAnalytics
            network={network}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route
        path="analytics/people"
        element={
          <PeopleAnalytics
            network={network}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route
        path="analytics/posts"
        element={
          <PostsAnalytics
            network={network}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route
        path="analytics/spaces"
        element={
          <SpacesAnalytics
            network={network}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route
        path="analytics/reach"
        element={
          <ReachAnalytics
            network={network}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route
        path="analytics/messages"
        element={
          <MessagesAnalytics
            network={network}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route path="audit-logs" element={<AuditLogs />} />
    </Routes>
  )
}
