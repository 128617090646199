import { clsx } from 'clsx'

import { ContentRenderer } from '../Components/ContentRenderer.js'
import { MediaRenderer } from '../Components/MediaRenderer.js'
import { type HeroBannerProps } from '../types.js'
import { getContentSpacingClass } from '../utils/utils.js'

export const FullLayout = ({
  contentSpacing,
  icon,
  eyebrow,
  heading,
  description,
  textColor,
  textAlignment,
  mediaType,
  image,
  video,
  mediaTint,
  backgroundColor,
  actionType,
  buttonActions,
  searchAction,
}: HeroBannerProps) => {
  const spacingClass = getContentSpacingClass(contentSpacing)

  return (
    <div
      className="relative rounded-base overflow-hidden shadow-card screen-rounded-none border-base border-card screen-border-x-0"
      style={{ backgroundColor }}
    >
      <ContentRenderer
        actionType={actionType}
        buttonActions={buttonActions}
        searchAction={searchAction}
        textColor={textColor}
        textAlignment={textAlignment}
        icon={icon}
        eyebrow={eyebrow}
        heading={heading}
        description={description}
        className={clsx('px-6 @xl:px-16 @5xl:px-48', spacingClass)}
      />
      <MediaRenderer
        mediaType={mediaType}
        image={image}
        video={video}
        mediaTint={mediaTint}
        textColor={textColor}
      />
    </div>
  )
}
