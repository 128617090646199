import { FC, ReactNode } from 'react'

import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { IconName } from '@bettermode/icons/types'
import { logger } from '@tribeplatform/react-components/common'

import { useBackgroundContext } from '../BackgroundContext/index.js'
import { Icon, SvgIcon } from '../Icon/index.js'
import type { IconComponentType } from '../Icon/types.js'
import { HTMLTribeProps } from '../types/index.js'
import {
  NavVerticalContext,
  NavVerticalSize,
  useNavVerticalContext,
} from './NavVerticalContext.js'

export type NavVerticalProps = HTMLTribeProps<'nav'> & {
  collapsed?: boolean
  size?: NavVerticalSize
}

/**
 * Used to create a side navigation
 */
export const NavVertical = ({
  as,
  children,
  className,
  collapsed = false,
  size = 'md',
  ...rest
}: NavVerticalProps) => {
  const Component = as || 'nav'

  return (
    <Component
      className={twMerge(
        clsx(
          'flex flex-col space-y-8 isolate text-label-md',
          collapsed ? 'items-center' : 'w-full',
          className,
        ),
      )}
      aria-label="Sidebar"
      {...rest}
    >
      <NavVerticalContext.Provider value={{ collapsed, size }}>
        {children}
      </NavVerticalContext.Provider>
    </Component>
  )
}

export type NavVerticalItemProps = HTMLTribeProps<'a'> & {
  active?: boolean
  leadingIcon?: IconComponentType
  leadingAddon?: ReactNode
  trailingIcon?: IconName
  trailingAddon?: ReactNode
  [key: string]: unknown
}

const NavVerticalItem: FC<NavVerticalItemProps> = props => {
  const {
    children,
    as,
    leadingIcon,
    leadingAddon,
    trailingIcon,
    trailingAddon,
    active = false,
    className,
    ...rest
  } = props
  const Component = as || 'a'

  const { collapsed, size } = useNavVerticalContext()
  const { backgroundType } = useBackgroundContext()

  const itemClsx = [
    backgroundType === 'main' && [
      active
        ? 'bg-background-pressed text-content-on-background-pressed'
        : 'bg-background text-content-on-background-subdued hover:bg-background-hovered hover:text-content-on-background-hovered',
    ],
    backgroundType === 'surface' && [
      active
        ? 'bg-surface-pressed text-content-pressed'
        : 'bg-surface text-content-subdued hover:bg-surface-hovered hover:text-content-hovered',
    ],
    backgroundType === 'secondary' && [
      active
        ? 'bg-action-primary-subdued text-content-primary'
        : 'bg-topbar text-content-on-topbar hover:bg-topbar hover:text-content-on-topbar',
    ],
    'focus:outline-none focus-visible:ring ring-inset ring-offset-0',
  ]

  const iconClsx = [
    size === 'md' && 'text-lg',
    size === 'lg' && 'text-xl',
    backgroundType === 'main' && [
      active
        ? 'text-content-on-background-pressed'
        : 'text-content-on-background-subdued group-hover:text-content-on-background-hovered',
    ],
    backgroundType === 'surface' && [
      active
        ? 'text-content-pressed'
        : 'text-content-subdued group-hover:text-content-hovered',
    ],
    backgroundType === 'secondary' && [
      active
        ? 'text-content-primary'
        : 'text-content-on-topbar group-hover:text-content-on-topbar',
    ],
  ]

  const paddingClsx = [
    size === 'md' && ' px-3 py-2',
    size === 'lg' && ' px-4 py-4',
  ]

  const content =
    typeof children === 'string' ? (
      <span className="flex-grow truncate">{children}</span>
    ) : (
      children
    )

  const leadingElement = leadingIcon ? (
    <Icon
      size="lg"
      className={clsx(iconClsx, !collapsed && '-ms-1 me-2')}
      aria-hidden="true"
    >
      {leadingIcon}
    </Icon>
  ) : (
    leadingAddon
  )

  const trailingElement = trailingIcon ? (
    <SvgIcon
      size="lg"
      className={clsx(iconClsx, '-me-1 ms-3')}
      name={trailingIcon}
    />
  ) : (
    trailingAddon
  )

  return (
    <Component
      className={clsx(
        'group flex items-center text-label-md rounded-base w-full',
        'transition duration-200',
        itemClsx,
        paddingClsx,
        className,
      )}
      aria-current={active ? 'page' : undefined}
      {...rest}
    >
      {leadingElement}
      {!collapsed ? content : null}
      {!collapsed && trailingElement}
    </Component>
  )
}

const NavVerticalItemSecondary: FC<NavVerticalItemProps> = props => {
  const { children, leadingIcon, active = false, className, ...rest } = props

  const { collapsed } = useNavVerticalContext()
  const { backgroundType } = useBackgroundContext()

  const content =
    typeof children === 'string' ? (
      <span className="truncate">{children}</span>
    ) : (
      children
    )

  if (!leadingIcon && collapsed) {
    logger.warn('leading icon is required when using collapsed')
  }

  const itemClsx = [
    backgroundType === 'main' &&
      'text-content-on-background-subdued hover:text-content-on-background-hovered hover:bg-background-hovered',
    backgroundType === 'surface' &&
      'text-content-subdued hover:text-content-hovered hover:bg-surface-hovered',
    backgroundType === 'secondary' &&
      'text-content-on-topbar hover:text-content-on-topbar hover:bg-topbar',
  ]

  const iconClsx = [
    backgroundType === 'main' && [
      active
        ? 'text-content-on-background-subdued'
        : 'text-content-on-background-subdued group-hover:text-content-on-background-subdued',
    ],
    backgroundType === 'surface' && [
      active
        ? 'text-content-subdued'
        : 'text-content-subdued group-hover:text-content-hovered',
    ],
    backgroundType === 'secondary' && [
      active
        ? 'text-content-on-topbar'
        : 'text-content-on-topbar group-hover:text-content-on-topbar',
    ],
  ]

  return (
    <a
      className={clsx(
        'group flex items-center px-3 py-2 leading-none rounded-base',
        itemClsx,
        'focus:outline-none focus-visible:ring',
        className,
      )}
      aria-current={active ? 'page' : undefined}
      {...rest}
    >
      {leadingIcon && (
        <Icon
          size="lg"
          className={clsx(iconClsx, !collapsed && '-ms-1 me-3')}
          aria-hidden="true"
        >
          {leadingIcon}
        </Icon>
      )}
      {!collapsed ? content : null}
    </a>
  )
}

export type NavVerticalGroupProps = HTMLTribeProps<'div'>

export const NavVerticalGroup: FC<NavVerticalGroupProps> = props => {
  const { children, className, ...rest } = props
  const { size } = useNavVerticalContext()

  return (
    <div
      className={clsx(size === 'lg' ? 'space-y-2' : 'space-y-1', className)}
      role="group"
      {...rest}
    >
      {children}
    </div>
  )
}

export type NavVerticalGroupHeaderProps = HTMLTribeProps<'h3'>

export const NavVerticalGroupHeader: FC<
  NavVerticalGroupHeaderProps
> = props => {
  const { children, className, ...rest } = props

  return (
    <h3
      className={clsx(
        'text-sm font-semibold text-content-on-background-subdued',
        className,
      )}
      {...rest}
    >
      {children}
    </h3>
  )
}

NavVertical.Item = NavVerticalItem
NavVertical.ItemSecondary = NavVerticalItemSecondary

NavVertical.Group = NavVerticalGroup
NavVertical.GroupHeader = NavVerticalGroupHeader
