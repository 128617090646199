import { type PathMatch } from 'react-router'

import {
  convertLegacyMatchToPathMatch,
  legacyMatchPath,
} from '@bettermode/common/legacy-match-path'
import { Space } from '@tribeplatform/gql-client/types'

import { generatePath as dumbGeneratePath } from '../../utils/space.utils.js'
import { SystemSpaceSlug } from './useSpaceUtil.js'

// These functions are extracted from useSpaceUtils hook and have stable reference

const findOne = (systemSpaces: Space[], slug: SystemSpaceSlug) =>
  systemSpaces?.find(space => space.slug === slug)

export const generatePath = (
  systemSpaces: Space[],
  spaceOrSlug: Partial<Space> | SystemSpaceSlug,
  params?: Record<string, string>,
  queryParams?: Record<string, string>,
): string => {
  const space =
    typeof spaceOrSlug === 'string'
      ? findOne(systemSpaces, spaceOrSlug)
      : spaceOrSlug
  return dumbGeneratePath(space, params, queryParams).replace(/\/\//g, '/')
}

export const matchPath = (
  systemSpaces: Space[],
  spaceOrSlug: Partial<Space> | SystemSpaceSlug,
  path: string,
  params?: Record<string, string>,
): PathMatch | null => {
  if (!spaceOrSlug) {
    return null
  }
  if (path === null || path === undefined) {
    return null
  }

  const space =
    typeof spaceOrSlug === 'string'
      ? findOne(systemSpaces, spaceOrSlug)
      : spaceOrSlug
  const spacePath = generatePath(systemSpaces, spaceOrSlug, params).replace(
    /^\/+|\/+$/g,
    '',
  )
  const firstPath = path.replace(/^\/+|\/+$/g, '')
  const secondaryPath = `/${firstPath}`
  const legacyResult =
    legacyMatchPath(firstPath, {
      path: spacePath,
      exact: space?.address?.exact,
      strict: false,
    }) ||
    legacyMatchPath(secondaryPath, {
      path: spacePath,
      exact: space?.address?.exact,
      strict: false,
    })

  if (!space && !spacePath) {
    return null
  }

  if (legacyResult === null) {
    return null
  }
  return convertLegacyMatchToPathMatch(legacyResult)
}
