import { AnalyticsPlanLimits, PlanName } from '@tribeplatform/gql-client/types'

import { dayjs } from '../../common/lib/dayjs.js'
import { I18nShape } from '../../i18n/types.js'
import {
  isHigherPlan,
  getTranslatedPlanName as getPlanName,
} from '../../utils/plan.js'
import { TimeFrame } from '../types/index.js'

export const DateTimeFormat = 'YYYY-MM-DD'

export const getDateString = (date: Date) => {
  if (!date) {
    return ''
  }

  return dayjs(date).format(DateTimeFormat)
}

interface GetRangeDateText {
  from: number
  to: number
  format?: string
}
export const getRangeDateText = ({
  from,
  to,
  format = 'MMM D',
}: GetRangeDateText) => {
  const fromDayjs = dayjs(from)
  const toDayjs = dayjs(to)
  const fromString = fromDayjs.format(format)
  const toString = toDayjs.format(format)

  if (fromDayjs.isSame(toDayjs, 'day')) {
    return fromString
  }

  return `${fromString} - ${toString}`
}

export const parseDateString = (
  dateString: string,
  format: string,
): Date | false => {
  const dateDayjs = dayjs(dateString, format, true)

  if (!dateDayjs.isValid()) {
    return false
  }

  return dateDayjs.toDate()
}

export const isDateFuture = (date: Date) => {
  return dayjs(date).isAfter(dayjs(), 'day')
}

export const getRequiredPlan = (
  intl: I18nShape,
  date: Date,
  daysLimitation: number,
  planLimits: AnalyticsPlanLimits[],
) => {
  if (daysLimitation === Infinity) {
    return ''
  }

  const duration = dayjs().diff(date, 'day') + 1
  if (duration <= daysLimitation) {
    return ''
  }

  return getPlanName(
    intl,
    getPlanNameByDaysLimitation(duration * 2, planLimits) as PlanName,
  )
}

export const getTimeframeIntlTexts = ({ $t }: I18nShape) =>
  ({
    today: $t({
      id: 'Analytics.TimeFrame.Today',
      defaultMessage: 'Today',
    }),
    yesterday: $t({
      id: 'Analytics.TimeFrame.Yesterday',
      defaultMessage: 'Yesterday',
    }),
    allTime: $t({
      id: 'Analytics.TimeFrame.AllTime',
      defaultMessage: 'All time',
    }),
    hourly: $t({
      id: 'Analytics.TimeFrame.Hourly',
      defaultMessage: 'hourly',
    }),
    daily: $t({
      id: 'Analytics.TimeFrame.Daily',
      defaultMessage: 'daily',
    }),
    weekly: $t({
      id: 'Analytics.TimeFrame.Weekly',
      defaultMessage: 'weekly',
    }),
    monthly: $t({
      id: 'Analytics.TimeFrame.Monthly',
      defaultMessage: 'monthly',
    }),
    lastXDays: (dayCount: number) =>
      $t(
        {
          id: 'Analytics.TimeFrame.LastXDays',
          defaultMessage: 'Last {day_count} days',
          description: 'Last x days time-frame (EG: Last 28 days)',
        },
        {
          day_count: dayCount.toString(),
        },
      ),
    lastXMonths: (monthCount: number) =>
      $t(
        {
          id: 'Analytics.TimeFrame.LastXMonths',
          defaultMessage: 'Last {month_count} months',
          description: 'Last x months time-frame (EG: Last 12 months)',
        },
        {
          month_count: monthCount.toString(),
        },
      ),
  } as const)

export const getTimeFrames = (intl: I18nShape): TimeFrame[] => {
  const texts = getTimeframeIntlTexts(intl)
  const today = dayjs()

  return [
    {
      id: 'today',
      text: texts.today,
      granularity: 'hour',
      granularityText: texts.hourly,
      from: today.startOf('day').unix() * 1000,
      to: today.unix() * 1000,
      xAxisCount: 24,
    },
    {
      id: 'yesterday',
      text: texts.yesterday,
      granularity: 'hour',
      granularityText: texts.hourly,
      from: today.subtract(1, 'day').startOf('day').unix() * 1000,
      to: today.subtract(1, 'day').endOf('day').unix() * 1000,
      xAxisCount: 24,
    },
    {
      id: 'last7Days',
      text: texts.lastXDays(7),
      granularity: 'day',
      granularityText: texts.daily,
      from: today.subtract(6, 'day').unix() * 1000,
      to: today.unix() * 1000,
      xAxisCount: 7,
    },
    {
      id: 'last28Days',
      text: texts.lastXDays(28),
      granularity: 'day',
      granularityText: texts.daily,
      from: today.subtract(28, 'day').unix() * 1000,
      to: today.unix() * 1000,
      xAxisCount: 28,
    },
    {
      id: 'last90Days',
      text: texts.lastXDays(90),
      granularity: 'day',
      granularityText: texts.daily,
      from: today.subtract(90, 'day').unix() * 1000,
      to: today.unix() * 1000,
      xAxisCount: 90,
    },
    {
      id: 'last12Months',
      text: texts.lastXMonths(12),
      granularity: 'month',
      granularityText: texts.monthly,
      from: today.subtract(11, 'month').unix() * 1000,
      to: today.unix() * 1000,
      xAxisCount: 12,
    },
  ]
}

interface CreateDynamicTimeFrameInput {
  id: string
  text: string
  from: number
  to: number
}
export const createDynamicTimeFrame = (intl: I18nShape) => {
  const texts = getTimeframeIntlTexts(intl)
  return ({ id, text, from, to }: CreateDynamicTimeFrameInput) => {
    const normalizedTo = dayjs(to)
    const normalizedFrom = dayjs(from)
    const dayDuration = normalizedTo.diff(normalizedFrom, 'day') + 1
    const monthDuration = normalizedTo.diff(normalizedFrom, 'month') + 1

    const timeFrame: TimeFrame = {
      id,
      text,
      granularity: 'month',
      granularityText: texts.monthly,
      from,
      to,
      xAxisCount: monthDuration,
    }

    if (dayDuration === 1) {
      timeFrame.granularity = 'hour'
      timeFrame.granularityText = texts.hourly
      timeFrame.xAxisCount = 24
    } else if (dayDuration <= 90) {
      timeFrame.granularity = 'day'
      timeFrame.granularityText = texts.daily
      timeFrame.xAxisCount = dayDuration
    }

    return timeFrame
  }
}

const getPlanNameByDaysLimitation = (
  daysLimitation: number,
  planLimits: AnalyticsPlanLimits[],
) => {
  const planName =
    planLimits?.find(limit => limit?.daysLimitation >= daysLimitation)?.plan ??
    planLimits?.find(limit => limit?.daysLimitation === null)?.plan ??
    ''

  return planName
}

export const getDaysLimitationByPlanName = (
  planName: string,
  planLimits: AnalyticsPlanLimits[],
) => {
  if (!planName || !planLimits) {
    return 0
  }

  const daysLimitation = planLimits.find(limit => {
    return limit?.plan.toLowerCase() === planName.toLowerCase()
  })?.daysLimitation

  if (daysLimitation === null) {
    return Infinity
  }

  return daysLimitation ?? 0
}

const calculateDaysLimitations = (
  intl: I18nShape,
  timeFrames: TimeFrame[],
  planLimits: AnalyticsPlanLimits[],
  currentPlanName: PlanName,
) => {
  const daysLimitation = getDaysLimitationByPlanName(
    currentPlanName,
    planLimits,
  )

  return timeFrames.map(timeFrame => {
    if (
      timeFrame.id === 'allTime' &&
      !isHigherPlan(currentPlanName, PlanName.Advanced)
    ) {
      return {
        ...timeFrame,
        plan: getPlanName(
          intl,
          getPlanNameByDaysLimitation(Infinity, planLimits) as PlanName,
        ),
      }
    }

    const daysOffset = Math.ceil(dayjs().diff(timeFrame.from, 'day', true)) * 2

    if (daysOffset > daysLimitation) {
      return {
        ...timeFrame,
        plan: getPlanName(
          intl,
          getPlanNameByDaysLimitation(daysOffset, planLimits) as PlanName,
        ),
      }
    }

    return timeFrame
  })
}

export const getAllTimeFrames = (
  intl: I18nShape,
  startOfTime: number,
  planLimits: AnalyticsPlanLimits[],
  currentPlanName: PlanName,
) => {
  const allTime = createDynamicTimeFrame(intl)({
    id: 'allTime',
    text: getTimeframeIntlTexts(intl).allTime,
    from: startOfTime,
    to: dayjs().unix() * 1000,
  })
  const currentTimeFrames = [...getTimeFrames(intl), allTime]

  return calculateDaysLimitations(
    intl,
    currentTimeFrames,
    planLimits,
    currentPlanName,
  )
}
