import { useCallback, useMemo, useState } from 'react'

import { getLocaleDetails } from '@tribeplatform/react-components/i18n'
import { useAuthToken, useUpdateMember } from '@tribeplatform/react-sdk/hooks'

import { LanguageIcon } from '../Components/LanguageIcon.js'
import { DropDownItemType, type DropDownItem } from '../types.js'

export const useLanguageSwitch = () => {
  const {
    data: { member, network },
  } = useAuthToken()
  const [isLoading, setIsLoading] = useState(false)
  const { mutateAsync: updateMember } = useUpdateMember()
  const currentLocale = member.locale ?? 'en-US'

  const setLocale = useCallback(
    async (locale: string) => {
      setIsLoading(true)
      try {
        await updateMember({
          id: member?.id,
          input: {
            locale,
          },
        })
        window.location.reload()
      } catch (error) {
        setIsLoading(false)
      }
    },
    [member?.id, updateMember],
  )

  const availableLocales = useMemo<DropDownItem[]>(() => {
    const networkLocales = network?.availableLocales ?? ['en-US']

    return networkLocales.map(locale => {
      const { languageName } = getLocaleDetails(locale)
      return {
        type: DropDownItemType.regular,
        id: locale,
        label: languageName,
        icon: (
          <LanguageIcon
            locale={locale}
            className="mr-4 flex justify-center items-center"
          />
        ),
        action: () => setLocale(locale),
      }
    })
  }, [network?.availableLocales, setLocale])

  return { locale: currentLocale, items: availableLocales, isLoading }
}
