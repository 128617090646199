import { memo, lazy, Suspense, useCallback } from 'react'

import { useDecision } from '@optimizely/react-sdk'
import { clsx } from 'clsx'

import type { AppearanceStyle } from '@tribeplatform/design-system/types'
import { useTribeClient } from '@tribeplatform/react-sdk'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Tabs } from '@tribeplatform/react-ui-kit/Tabs'

import { ErrorBoundary } from '../common/components/ErrorBoundary/ErrorBoundary.js'
import { FeaturesList } from '../common/constants/features.js'
import { linkToFile } from '../common/lib/upload.js'
import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { ImageLinkPicker } from './ImageLinkPicker.js'
import { ImageSizeHelper } from './ImageSizeHelper.js'
import { ImageUpload } from './ImageUpload.js'
import type { ImageSizeConfig, ImagePickerTab, PickerEmoji } from './types.js'
import { PickerIcon } from './types.js'

const UnsplashPicker = lazy(() =>
  import('./Unsplash/Picker.js').then(m => ({
    default: m.UnsplashPicker,
  })),
)

const EmojiPicker = lazy(() =>
  import('./EmojiPicker.js').then(m => ({
    default: m.EmojiPicker,
  })),
)
const IconPicker = lazy(() =>
  import('./IconPicker.js').then(m => ({
    default: m.IconPicker,
  })),
)

export type ImagePickerProps = {
  tabs: ImagePickerTab[]
  removable?: boolean
  multiple?: boolean
  sizeConfig?: ImageSizeConfig
  iconStyle?: AppearanceStyle['icons']
  onEmojiChange?: (emoji: PickerEmoji) => void
  onIconChange?: (emoji: PickerIcon) => void
  onImageChange?: (imageFiles: Array<File>) => void
  onRemove?: () => void
  /** On selecting anything this function will also be called and you can use it to close the popover if you are using this component within a dropdown or modal for example. */
  onSelect?: () => void
  defaultQuery?: string
  fullWidth?: boolean
}

export const ImagePickerTabs = memo(
  ({
    onSelect,
    onEmojiChange,
    onIconChange,
    onImageChange,
    iconStyle,
    removable,
    onRemove,
    defaultQuery = '',
    fullWidth = false,
    tabs,
    multiple,
    sizeConfig,
  }: ImagePickerProps) => {
    const { config: tribeClientConfig } = useTribeClient()
    const { unsplashApiKey } = tribeClientConfig || {}
    const intl = useI18n()
    const [iconPickerDecision] = useDecision(FeaturesList.IconPicker)

    const {
      recommendedWidth,
      recommendedHeight,
      showSizeLimit = true,
    } = sizeConfig ?? {}

    const emojiTab = tabs.includes('emoji') && !!onEmojiChange
    const iconTab =
      iconPickerDecision.enabled && tabs.includes('icon') && !!onIconChange
    const imageTab = tabs.includes('upload') && !!onImageChange
    const linkTab = tabs.includes('link') && !!onImageChange
    const unsplashTab =
      tabs.includes('unsplash') && !!onImageChange && unsplashApiKey

    const onLinkChange = async (link: string) => {
      const file = await linkToFile(link, intl)
      if (file) {
        onImageChange([file])
        onSelect?.()
      }
    }

    const handleImageChange = (files: File[]) => {
      onImageChange(files)
      onSelect?.()
    }

    const handleEmojiChange = (emoji: PickerEmoji) => {
      onEmojiChange(emoji)
      onSelect?.()
    }

    const handleIconChange = useCallback(
      (emoji: PickerIcon) => {
        onIconChange(emoji)
        onSelect?.()
      },
      [onIconChange, onSelect],
    )

    const handleRemove = () => {
      onRemove()
      onSelect?.()
    }

    if (!emojiTab && !iconTab && !imageTab && !linkTab && !unsplashTab) {
      return null
    }

    return (
      <div className={!fullWidth && 'w-[22rem]'}>
        <Tabs.Group>
          <Tabs.List
            variant="filled"
            className={clsx(!fullWidth && 'p-2 pb-0', onRemove && 'space-s-0')}
            fullWidth
          >
            {emojiTab && (
              <Tabs.Tab name="emoji">
                <T id="Generics.Emojis" defaultMessage="Emojis" />
              </Tabs.Tab>
            )}
            {iconTab && (
              <Tabs.Tab name="icon">
                <T id="Generics.Icons" defaultMessage="Icons" />
              </Tabs.Tab>
            )}
            {imageTab && (
              <Tabs.Tab name="upload">
                <T id="Generics.Upload" defaultMessage="Upload" />
              </Tabs.Tab>
            )}
            {linkTab && (
              <Tabs.Tab name="link">
                <T id="Generics.Link.Noun" defaultMessage="Link" />
              </Tabs.Tab>
            )}
            {/* eslint-disable-next-line react/jsx-no-literals */}
            {unsplashTab && <Tabs.Tab name="unsplash">Unsplash</Tabs.Tab>}
            {removable && typeof onRemove === 'function' && (
              <Button
                variant="tertiaryNeutral"
                destructive
                className="shrink-0"
                onClick={handleRemove}
              >
                <T id="Generics.Remove" defaultMessage="Remove" />
              </Button>
            )}
          </Tabs.List>
          <Tabs.Panels>
            {emojiTab && (
              <Tabs.Panel className="-mt-3 w-[22rem] h-[435px] max-w-full mx-auto flex justify-center">
                <Suspense fallback={<></>}>
                  <EmojiPicker onEmojiChange={handleEmojiChange} />
                </Suspense>
              </Tabs.Panel>
            )}
            {iconTab && (
              <Tabs.Panel className="-mt-3 w-[22rem] h-[435px] max-w-full mx-auto flex justify-center pb-4">
                <Suspense fallback={<></>}>
                  <IconPicker
                    onIconChange={handleIconChange}
                    iconStyle={iconStyle}
                  />
                </Suspense>
              </Tabs.Panel>
            )}
            {imageTab && (
              <Tabs.Panel
                className={clsx(
                  'max-w-full h-64 mx-auto',
                  !fullWidth && 'w-[22rem] px-2 pb-2',
                )}
              >
                <Suspense fallback={<></>}>
                  <ImageUpload
                    multiple={multiple}
                    helper={
                      <ImageSizeHelper
                        recommendedWidth={recommendedWidth}
                        recommendedHeight={recommendedHeight}
                        showSizeLimit={showSizeLimit}
                      />
                    }
                    onChange={handleImageChange}
                  />
                </Suspense>
              </Tabs.Panel>
            )}
            {linkTab && (
              <Tabs.Panel
                className={clsx(
                  'max-w-full h-64 mx-auto',
                  !fullWidth && 'w-[22rem] px-2 pb-2',
                )}
              >
                <ImageLinkPicker onChange={onLinkChange} />
              </Tabs.Panel>
            )}
            {unsplashTab && (
              <Tabs.Panel
                className={clsx(
                  'max-w-full h-[328px] mx-auto',
                  !fullWidth && 'w-[22rem] px-2 pb-2',
                )}
              >
                <ErrorBoundary>
                  <Suspense fallback={<></>}>
                    <UnsplashPicker
                      onChange={onLinkChange}
                      apiKey={unsplashApiKey}
                      defaultQuery={defaultQuery}
                    />
                  </Suspense>
                </ErrorBoundary>
              </Tabs.Panel>
            )}
          </Tabs.Panels>
        </Tabs.Group>
      </div>
    )
  },
)
