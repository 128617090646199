import { lazy } from 'react'

import { nanoid } from 'nanoid'

import {
  type Image,
  type File,
  GlyphMediaVariant,
} from '@tribeplatform/gql-client/types'
import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { HeroBannerBlock as Component } from './Block.js'
import {
  ActionType,
  ButtonActionType,
  HeroBannerLayout,
  MediaType,
  SearchFor,
  Target,
  TextAlignment,
  TextColor,
  SearchSource,
  type ButtonAction,
  type HeroBannerProps,
} from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.HeroBannerBlockSettings })),
)

export const genericButtonAction: ButtonAction = {
  id: '',
  url: '',
  name: '',
  type: ButtonActionType.secondaryButton,
  target: Target.blank,
}

export const RegisteredHeroBannerBlock = (
  intl: I18nShape,
): RegisteredBlock<HeroBannerProps> => {
  const { $t } = intl

  return {
    recommendedName: 'NewHeroBanner',
    Component,
    config: {
      type: BlockType.Block,
      usable: true,
      fullSidebarSettings: true,

      displayName: $t({
        id: 'Blocks.HeroBanner.DisplayName',
        defaultMessage: 'Hero banner',
      }),
      Settings,
      Icon: <SvgIcon name="cursor-click" />,
      initialProps: {
        layout: HeroBannerLayout.imageFull,
        contentSpacing: 5,
        mediaAspectRatio: 6,
        icon: {
          id: 'emoji/1f37f',
          text: '1f37f',
          variant: GlyphMediaVariant.emoji,
        },
        eyebrow: '',
        heading: "It's showtime!",
        description:
          "Ultimate Cinematic Experience: It's Showtime! Get Ready for Movie Magic",
        textColor: TextColor.light,
        textAlignment: TextAlignment.start,
        mediaType: MediaType.video,
        image: {
          url: 'https://tribe-s3-production.imgix.net/kegOb7eY1SokwxR88MD3m?auto=compress,format',
        } as Image,
        video: {
          url: 'https://files-us-east-1.t-cdn.net/files/8qpM3eYRW3PkeNlDmLBBY',
          downloadUrl:
            'https://files-us-east-1.t-cdn.net/files/8qpM3eYRW3PkeNlDmLBBY',
        } as File,
        mediaTint: 25,
        backgroundColor: '#000',
        actionType: ActionType.button,
        buttonActions: [
          {
            id: nanoid(),
            type: ButtonActionType.primaryButton,
            name: $t({
              id: 'Generics.CallToAction',
              defaultMessage: 'Call to action',
            }),
            url: '',
            target: Target.blank,
          },
        ],
        searchAction: {
          searchFor: SearchFor.all,
          searchSource: SearchSource.allSpaces,
          spaceIds: [],
        },
      },
    },
  }
}
