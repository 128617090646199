import { ReactNode, ComponentPropsWithoutRef } from 'react'

import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react'
import { clsx } from 'clsx'

import { runIfFn } from '../utils/index.js'
import {
  optionDescriptorDescriptionStyles,
  optionDescriptorTitleStyles,
} from '../utils/OptionDescriptor.styles.js'
import { Radio } from './Radio.js'
import { radioGroupItemStyles } from './RadioGroup.styles.js'
import { useRadioGroups } from './RadioGroupContext.js'

interface RadioGroupItemRenderProps {
  checked: boolean
}

export type RadioGroupItemProps<T = unknown> = Omit<
  ComponentPropsWithoutRef<'div'>,
  'title' | 'children'
> & {
  value: T
  disabled?: boolean
  /** If title is set, children are ignored */
  title?: string | ReactNode
  description?: string | ReactNode
  children?: ReactNode | ((props: RadioGroupItemRenderProps) => ReactNode)
  hideRadio?: boolean
}

export const RadioGroupItem = ({
  value,
  title,
  description,
  children,
  disabled,
  className,
  hideRadio = false,
  ...rest
}: RadioGroupItemProps) => {
  const { variant } = useRadioGroups()

  return (
    <HeadlessRadioGroup.Option
      as="div"
      className={radioGroupItemStyles({
        variant,
        className,
      })}
      value={value}
      disabled={disabled}
      {...rest}
    >
      {renderProps => (
        <>
          {!hideRadio && (
            <Radio
              checked={renderProps.checked}
              disabled={renderProps.disabled}
            />
          )}
          <div className="min-w-0 grow">
            {title ? (
              <>
                <HeadlessRadioGroup.Label
                  as="div"
                  className={optionDescriptorTitleStyles({
                    disabled: renderProps.disabled,
                  })}
                >
                  {title}
                </HeadlessRadioGroup.Label>
                {description && (
                  <HeadlessRadioGroup.Description
                    as="div"
                    className={clsx(
                      optionDescriptorDescriptionStyles({
                        disabled: renderProps.disabled,
                      }),
                      'mb-1',
                    )}
                  >
                    {description}
                  </HeadlessRadioGroup.Description>
                )}
              </>
            ) : (
              <div className={optionDescriptorTitleStyles({ disabled })}>
                {runIfFn(children, renderProps)}
              </div>
            )}
          </div>
        </>
      )}
    </HeadlessRadioGroup.Option>
  )
}
