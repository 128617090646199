import { useState } from 'react'

import type {
  AnalyticsPlanLimits,
  PlanName,
} from '@tribeplatform/gql-client/types'
import { Select } from '@tribeplatform/react-ui-kit/Select'

import { PlanBadge } from '../../common/components/PlanBadge.js'
import { T } from '../../i18n/components/T.js'
import { useStaticIntl } from '../../i18n/hooks/useStaticIntl.js'
import type { TimeFrame } from '../types/index.js'
import {
  getAllTimeFrames,
  getDaysLimitationByPlanName,
} from '../utils/timeFrame.js'
import { CustomTimeFrameModal } from './CustomTimeFrameModal.js'

interface Props {
  startOfTime: number
  timeFrame: TimeFrame
  setTimeFrame: (timeFrame: TimeFrame) => void
  planLimits: AnalyticsPlanLimits[]
  currentPlanName: PlanName
}

export const TimeFrameSelect = ({
  startOfTime,
  timeFrame,
  setTimeFrame,
  planLimits,
  currentPlanName,
}: Props) => {
  const [showModal, setShowModal] = useState(false)
  const daysLimitation =
    getDaysLimitationByPlanName(currentPlanName, planLimits) / 2

  const allTimeFrames = useStaticIntl(
    getAllTimeFrames,
    startOfTime,
    planLimits,
    currentPlanName,
  )

  const handleTimeFrameChange = (id: string) => {
    const timeFrame = allTimeFrames.find(timeFrame => timeFrame.id === id)

    if (id === 'custom') {
      setShowModal(true)
      return
    }

    setTimeFrame(timeFrame)
  }

  return (
    <>
      <Select
        onChange={handleTimeFrameChange}
        value={timeFrame.id}
        className="min-w-60"
      >
        <Select.Button placeholder="Select Timeframe">
          <span className="text-content">
            <T
              id="Analytics.TimeFrameSelect.Label"
              defaultMessage="Timeframe:"
              description="Label for time frame select"
            />{' '}
          </span>
          <span className="text-interactive">{timeFrame.text}</span>
        </Select.Button>
        <Select.Items className="max-h-80">
          {allTimeFrames.map(timeFrame => (
            <Select.Item
              key={timeFrame.id}
              value={timeFrame.id}
              disabled={!!timeFrame?.plan}
            >
              <div className="w-full flex justify-between">
                <div className="flex-1 truncate" title={timeFrame.text}>
                  {timeFrame.text}
                </div>
                {timeFrame?.plan && <PlanBadge authorized={false} />}
              </div>
            </Select.Item>
          ))}
          <Select.Item value="custom">
            <T
              id="Analytics.TimeFrameSelect.CustomLabel"
              defaultMessage="Custom"
              description="Label for custom item in timeframe select"
            />
          </Select.Item>
        </Select.Items>
      </Select>
      {showModal && (
        <CustomTimeFrameModal
          open={showModal}
          onClose={() => setShowModal(false)}
          daysLimitation={daysLimitation}
          planLimits={planLimits}
          timeFrame={timeFrame}
          setTimeFrame={setTimeFrame}
        />
      )}
    </>
  )
}
