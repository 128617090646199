import { useEffect, useState, useRef, useCallback, ReactNode } from 'react'

import { useDecision } from '@optimizely/react-sdk'
import type {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query'
import InfiniteScroll from 'react-infinite-scroller'

import { Post as PostType } from '@tribeplatform/gql-client/types'
import { useTracker } from '@tribeplatform/react-sdk'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import { FeaturesList } from '../common/constants/features.js'
import {
  PostCardListLoading,
  PostRowListLoading,
} from '../LoadingStates/PostCardLoading.js'
import { Post } from '../Post/Post.js'
import type { PostContext } from '../Post/types.js'
import { ReplyWithParent } from '../Reply/ReplyWithParent.js'

const MARK_POST_AS_VIEWED_AFTER_MS = 1000
const observerOptions = {
  root: null,
  rootMargin: '0px 0px -30% 0px',
  threshold: 1.0,
}

export type GenericPostListProps = {
  posts: PostType[]
  pinnedPosts?: PostType[]
  context: PostContext
  activeTagId?: string
  activeTagSlug?: string
  emptyState?: ReactNode
  fetchNextPage:
    | false
    | ((options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult>)
  hasNextPage: boolean
  isLoading: boolean
  isFetched: boolean
  isFetchingNextPage: boolean
  view?: 'card' | 'row'
}
export const GenericPostList = ({
  posts,
  context,
  pinnedPosts = [],
  activeTagId = '',
  activeTagSlug = '',
  emptyState,
  fetchNextPage,
  hasNextPage,
  isLoading,
  isFetched,
  isFetchingNextPage,
  view = 'card',
}: GenericPostListProps) => {
  const tracker = useTracker()
  const [activeView, setActiveView] = useState(view)
  const scrollObserverRef = useRef<IntersectionObserver>()
  const viewedPosts = useRef<Record<string, boolean>>({})
  const timeOutHandles = useRef<Record<string, NodeJS.Timeout>>({})
  const [decisionViewPostOnVisible] = useDecision(
    FeaturesList.ViewPostOnVisible,
  )

  const markPostAsViewed = useCallback(
    (postId: string) => {
      viewedPosts.current[postId] = true
      timeOutHandles.current[postId] = undefined
      if (decisionViewPostOnVisible.enabled) {
        tracker.setTarget({ postId })
        tracker.trackPageView(postId)
      }
    },
    [decisionViewPostOnVisible.enabled, tracker],
  )

  const startTimer = useCallback(
    (postId: string) => {
      timeOutHandles.current[postId] = setTimeout(
        () => markPostAsViewed(postId),
        MARK_POST_AS_VIEWED_AFTER_MS,
      )
    },
    [markPostAsViewed],
  )

  const stopTimer = useCallback((postId: string) => {
    clearTimeout(timeOutHandles.current[postId])
    timeOutHandles.current[postId] = undefined
  }, [])

  useEffect(() => {
    setActiveView(view)
  }, [view])

  useEffect(() => {
    scrollObserverRef.current = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const postId = entry.target?.id ?? ''
        if (entry.isIntersecting) {
          if (
            !viewedPosts.current?.[postId] &&
            !timeOutHandles.current?.[postId]
          ) {
            startTimer(postId)
          }
        } else if (timeOutHandles.current?.[postId]) {
          stopTimer(postId)
        }
      })
    }, observerOptions)

    return () => {
      if (scrollObserverRef.current) {
        scrollObserverRef.current.disconnect()
      }
    }
  }, [startTimer, stopTimer])

  const content = (
    <>
      {pinnedPosts.map(post => {
        return (
          <Post
            key={post.id}
            post={post}
            context={context}
            activeTagId={activeTagId}
            activeTagSlug={activeTagSlug}
            view={activeView}
            scrollObserver={scrollObserverRef.current}
          />
        )
      })}
      {posts.map(post => {
        if (post?.repliedToId) {
          return (
            <ReplyWithParent key={post.id} reply={post} context={context} />
          )
        }
        return (
          <Post
            key={post.id}
            post={post}
            context={context}
            activeTagId={activeTagId}
            activeTagSlug={activeTagSlug}
            view={activeView}
            scrollObserver={scrollObserverRef.current}
          />
        )
      })}
    </>
  )

  if (
    isFetched &&
    !isLoading &&
    !posts?.length &&
    !pinnedPosts?.length &&
    emptyState
  ) {
    return <>{emptyState}</>
  }

  return (
    <div className="post-list">
      <InfiniteScroll
        pageStart={0}
        loadMore={fetchNextPage}
        hasMore={hasNextPage && !isLoading && !isFetchingNextPage}
        threshold={800}
      >
        {activeView === 'row' ? (
          <Card className="flex flex-col divide-y divide-line-subdued">
            {content}
            {!isFetched || isLoading || isFetchingNextPage ? (
              <PostRowListLoading />
            ) : null}
          </Card>
        ) : (
          <div className="flex flex-col space-y-5">
            {content}
            {!isFetched || isLoading || isFetchingNextPage ? (
              <PostCardListLoading />
            ) : null}
          </div>
        )}
      </InfiniteScroll>
    </div>
  )
}
