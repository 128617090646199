import { ReactElement, useMemo, useState } from 'react'

import type { CustomSpaceFields } from '@tribeplatform/gql-client/graphql'
import {
  QuerySpacesArgs,
  Space,
  SpaceType,
} from '@tribeplatform/gql-client/types'
import { useSpaces } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Multiselect } from '@tribeplatform/react-ui-kit/Multiselect'
import { SearchableSelect } from '@tribeplatform/react-ui-kit/SearchableSelect'

import { useDebounce } from '../common/hooks/useDebounce.js'
import { T } from '../i18n/components/T.js'
import { SpaceImage } from '../Space/SpaceImage.js'
import { pickIfExists } from './arrays.utils.js'

type SpacePickerSingleProps = {
  value?: Space
  onChange: (values: Space) => void
}
type SpacePickerMultipleProps = {
  value?: Space[]
  onChange: (values: Space[]) => void
}

export type SpacePickerProps = (
  | SpacePickerSingleProps
  | SpacePickerMultipleProps
) & {
  placeholder?: string
  variables?: Omit<QuerySpacesArgs, 'query'>
  fields?: CustomSpaceFields
  options?: Space[]
  /**
   * These options will appear at the top of the picker when no search is set.
   * Useful when we have contextual pre-loaded entities from other sources.
   */
  staticOptions?: Space[]
  disabled?: boolean
  /**
   * Allows to pick multiple values at once
   * @default false
   */
  multiple?: boolean
  /**
   * Whether the user can clear the select field
   * @default true
   */
  clearable?: boolean
  noWrapper?: boolean
  invalid?: boolean
  className?: string
  additionalIndicatorRenderer?: (space: Space) => ReactElement
  customFilter?: (space: Space) => boolean
}

const isMultiple = (
  props: SpacePickerProps,
): props is SpacePickerMultipleProps => props.multiple === true

export const SpacePicker = (props: SpacePickerProps) => {
  const {
    placeholder,
    variables,
    options,
    staticOptions = [],
    disabled,
    clearable = true,
    noWrapper,
    invalid,
    className,
    fields,
    customFilter,
    additionalIndicatorRenderer,
  } = props

  const [search, setSearch] = useState('')
  const debouncedQuery = useDebounce(search, 300)

  const { data, isInitialLoading } = useSpaces({
    fields: {
      image: 'basic',
      ...fields,
    },
    variables: {
      query: debouncedQuery,
      limit: 20,
      type: [SpaceType.Group],
      ...variables,
    },
    useInfiniteQueryOptions: {
      enabled: !options,
    },
  })

  const spaces = useMemo(() => {
    if (options) {
      return options.filter(option =>
        option.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      )
    }

    const spaceOptions = simplifyPaginatedResult<Space>(data)?.nodes || []
    if (customFilter) {
      return spaceOptions.filter(customFilter)
    }
    if (!search) {
      return [...staticOptions, ...spaceOptions]
    }
    return spaceOptions
  }, [options, data, customFilter, search, debouncedQuery, staticOptions])

  if (isMultiple(props)) {
    const { value, onChange } = props
    const suggestedSpaces: Space[] = pickIfExists(spaces, value, 'id')

    return (
      <Multiselect
        value={value || []}
        options={suggestedSpaces}
        onChange={onChange}
        searchable
        onInputChange={setSearch}
        disabled={disabled}
        className={className}
        invalid={invalid}
      >
        <Multiselect.Button
          placeholder={placeholder}
          hideInput={value?.length > 0}
        >
          {value?.map((space, index) => (
            <Multiselect.SelectedItem
              className="px-1 pe-2"
              key={space.id}
              value={space}
              index={index}
            >
              <SpaceSelectItem
                space={space}
                additionalIndicatorRenderer={additionalIndicatorRenderer}
              />
            </Multiselect.SelectedItem>
          ))}
        </Multiselect.Button>
        <Multiselect.Items noWrapper={noWrapper}>
          {suggestedSpaces.map((space, index) => (
            <Multiselect.Item key={space.id} value={space} index={index}>
              <SpaceSelectItem
                space={space}
                additionalIndicatorRenderer={additionalIndicatorRenderer}
              />
            </Multiselect.Item>
          ))}
          {isInitialLoading && (
            <Multiselect.ItemsEmpty>
              <T id="Generics.LoadingDotDotDot" defaultMessage="Loading..." />
            </Multiselect.ItemsEmpty>
          )}
          {!isInitialLoading && search && suggestedSpaces.length === 0 && (
            <Multiselect.ItemsEmpty>
              <T id="Generics.NoResults" defaultMessage="No results" />
            </Multiselect.ItemsEmpty>
          )}
        </Multiselect.Items>
      </Multiselect>
    )
  }

  const { value, onChange } = props
  const suggestedSpaces: Space[] = value
    ? pickIfExists(spaces, [value], 'id')
    : spaces

  return (
    <SearchableSelect
      value={value}
      options={suggestedSpaces}
      onChange={onChange}
      onInputChange={setSearch}
    >
      <SearchableSelect.Button placeholder={placeholder} clearable={clearable}>
        {value && (
          <SpaceSelectItem
            space={value}
            additionalIndicatorRenderer={additionalIndicatorRenderer}
          />
        )}
      </SearchableSelect.Button>
      <SearchableSelect.Items>
        {suggestedSpaces.map(space => (
          <SearchableSelect.Item key={space.id} value={space.id}>
            <SpaceSelectItem
              space={space}
              additionalIndicatorRenderer={additionalIndicatorRenderer}
            />
          </SearchableSelect.Item>
        ))}
        {isInitialLoading && (
          <SearchableSelect.ItemsEmpty>
            <T id="Generics.LoadingDotDotDot" defaultMessage="Loading..." />
          </SearchableSelect.ItemsEmpty>
        )}
        {!isInitialLoading && search && suggestedSpaces.length === 0 && (
          <SearchableSelect.ItemsEmpty>
            <T id="Generics.NoResults" defaultMessage="No results" />
          </SearchableSelect.ItemsEmpty>
        )}
      </SearchableSelect.Items>
    </SearchableSelect>
  )
}

const SpaceSelectItem = (
  props: Pick<SpacePickerProps, 'additionalIndicatorRenderer'> & {
    space: Space
  },
) => {
  const { space, additionalIndicatorRenderer } = props
  return (
    <div className="flex items-center space-s-2">
      <SpaceImage size="lg" space={space} />
      <span className="truncate">{space?.name}</span>
      {additionalIndicatorRenderer && additionalIndicatorRenderer(space)}
    </div>
  )
}
