import { Buffer } from 'buffer'

import { DocumentNode } from 'graphql'
import {
  ClientError as BaseClientError,
  GraphQLClient as BaseGraphQLClient,
} from 'graphql-request'

import { createEvent, TribeEvent } from '../lib/index.js'
import {
  ApiErrorCodes,
  ClientError,
  ErrorResponse,
  flattenErrors,
} from '../lib/response.js'
import { parseToken } from '../lib/token.js'

export type TribeClientOptions = {
  graphqlUrl?: string
  accessToken?: string
  clientId?: string
  clientSecret?: string
  notifyOnTokenExpiration?: boolean
  fetch?: typeof fetch
  onError?: (
    errors: ErrorResponse[],
    client: GraphqlClient,
    error?: null | ClientError,
  ) => void
  onEvent?: (event: TribeEvent) => void
  gatewayCacheControl?: 'no-cache'
}

type RequestOptions = {
  query: DocumentNode
  variables?: Record<string, any>
  customToken?: string
  useBasicToken?: boolean
}

export class GraphqlClient extends BaseGraphQLClient {
  public accessToken?: string

  public graphqlUrl?: string

  private clientId?: string

  private clientSecret?: string

  private notifyOnTokenExpiration: boolean

  private onError?: TribeClientOptions['onError']

  private onEvent?: (event: TribeEvent) => void

  private tokenExpirationTimeout?: NodeJS.Timeout

  private tokenExpirationHandler(token) {
    clearTimeout(this.tokenExpirationTimeout)
    const parsedToken = parseToken(token)
    if (!parsedToken) {
      return
    }
    const diff =
      new Date(parsedToken.exp * 1000).getTime() - new Date().getTime()
    const timeout = diff - 60 * 1000 // invoke the error handler one minute sooner

    // This is due to setTimeout using a 32 bit int to store the delay so the max value allowed would be
    if (timeout > 2147483647) {
      return
    }

    this.tokenExpirationTimeout = setTimeout(() => {
      this.onError?.(
        [
          {
            code: ApiErrorCodes.INVALID_ACCESS_TOKEN,
            message: 'Invalid access token',
          },
        ],
        this,
        null,
      )
    }, timeout)
  }

  constructor(options: TribeClientOptions) {
    const {
      graphqlUrl = 'https://app.tribe.so/api/gateway',
      accessToken,
      clientId,
      clientSecret,
      notifyOnTokenExpiration,
      fetch,
      onError,
      onEvent,
      gatewayCacheControl,
    } = options
    super(graphqlUrl, { fetch })

    this.graphqlUrl = graphqlUrl
    this.accessToken = accessToken
    this.onError = onError
    this.onEvent = onEvent
    this.clientId = clientId
    this.clientSecret = clientSecret
    this.notifyOnTokenExpiration = !!notifyOnTokenExpiration
    if (this.notifyOnTokenExpiration) {
      this.tokenExpirationHandler(accessToken)
    }
    if (gatewayCacheControl) {
      this.setHeader('X-Bettermode-Cache-Control', gatewayCacheControl)
    }
  }

  private getBasicToken() {
    return Buffer.from(`${this.clientId}:${this.clientSecret}`).toString(
      'base64',
    )
  }

  async authorizedRequest<T>(options: RequestOptions): Promise<T> {
    const {
      query,
      variables = {},
      customToken = null,
      useBasicToken = false,
    } = options
    if (useBasicToken) {
      if (customToken) {
        this.setHeader('Authorization', `Basic ${customToken}`)
      } else if (this.clientId && this.clientSecret) {
        this.setHeader('Authorization', `Basic ${this.getBasicToken()}`)
      }
    } else if (customToken || this.accessToken) {
      this.setHeader(
        'Authorization',
        `Bearer ${customToken || this.accessToken}`,
      )
    }
    return this.request<T>(query, variables)
      .then(data => {
        const event = createEvent(query, data, variables)
        if (event && typeof this.onEvent === 'function') {
          this.onEvent(event)
        }
        return data
      })
      .catch((error: BaseClientError) => {
        if (error?.response?.errors) {
          const normalizedError: Array<ErrorResponse> = flattenErrors(
            error.response.errors,
          )

          error.response.errors = normalizedError

          this.onError?.(normalizedError, this, error as ClientError)
        }

        // eslint-disable-next-line no-throw-literal
        throw error as ClientError
      })
  }

  setToken(accessToken: string): void {
    this.accessToken = accessToken
    if (this.notifyOnTokenExpiration) {
      this.tokenExpirationHandler(accessToken)
    }
  }
}
