import { SystemSpaceSlug } from '@tribeplatform/react-sdk/hooks'
import { type SvgIconName } from '@tribeplatform/react-ui-kit/Icon'

export const SystemSpacesIcons: Record<string, SvgIconName> = {
  [SystemSpaceSlug.HOME]: 'home',
  [SystemSpaceSlug.SEARCH]: 'search',
  [SystemSpaceSlug.NOTIFICATIONS]: 'bell',
  [SystemSpaceSlug.PROFILE_SETTINGS]: 'member',
  [SystemSpaceSlug.SIGNUP]: 'login',
  [SystemSpaceSlug.LOGIN]: 'login',
  [SystemSpaceSlug.ACCEPT_INVITATION]: 'login',
  [SystemSpaceSlug.DIRECT_LOGIN]: 'login',
  [SystemSpaceSlug.FORGOT_PASSWORD]: 'login',
  [SystemSpaceSlug.JOIN_BY_INVITATION]: 'login',
  [SystemSpaceSlug.RESET_PASSWORD]: 'login',
  [SystemSpaceSlug.VERIFY_EMAIL]: 'login',
  'default::members': 'members',
  'default::member': 'member',
  'default::spaces': 'grid',
}
