type CacheControlProps = {
  baseUrl: string
}

export const CacheControl = ({ baseUrl }: CacheControlProps): JSX.Element => {
  return (
    <a
      href={`${baseUrl}?_bmcache=enable`}
      className="text-white text-[34px] leading-[34px] fixed z-[999999] no-underline m-[8px] border-[none] start-0 bottom-0"
    >
      <span
        className="inline-block relative p-[8px] before:bg-black before:text-[10px] before:leading-[12px] before:content-[attr(data-text)] before:absolute before:-translate-y-1/2 before:w-[200px] before:text-white before:text-center before:invisible before:opacity-0 before:transition-[visibility_0s,opacity_0.2s] before:delay-[0.3s] before:p-2.5 before:rounded-[10px] before:start-full before:top-1/2 hover:before:visible hover:before:opacity-100"
        data-text="Gateway caching is disabled for current session. Click here to re-enable it"
      >
        🫥
      </span>
    </a>
  )
}
