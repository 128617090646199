import { useEffect, useMemo } from 'react'

import type { Member, Network } from '@tribeplatform/gql-client/types'

import {
  ActorAnalyticsContext,
  SnowplowTracker,
  SnowplowTrackerFactory,
  TargetAnalyticsContext,
} from '../lib/snowplow.js'

function getLang(): string | undefined {
  if (typeof window === 'undefined') {
    return undefined
  }

  return navigator?.language
}

interface UseTracker {
  actor?: Partial<ActorAnalyticsContext>
  target?: Partial<TargetAnalyticsContext>
  member: Member
  network: Network
}

export const useTracker = ({
  actor,
  target,
  member,
  network,
}: UseTracker): SnowplowTracker => {
  const { tracker, defaultActor, defaultTarget } = useMemo(() => {
    const tracker = SnowplowTrackerFactory('tribe-analytics', {})

    return {
      tracker,
      defaultActor: tracker.actor,
      defaultTarget: tracker.target,
    }
  }, [])

  useEffect(() => {
    tracker.setActor({
      id: member?.id,
      roleId: member?.role?.id,
      roleType: member?.role?.type,
      locale: member?.locale || getLang(),
      ...actor,
    })

    tracker.setTarget({
      organizationId: network.organizationId,
      networkId: network.id,
      ...target,
    })

    return () => {
      tracker.setActor(defaultActor)
      tracker.setTarget(defaultTarget)
    }
  }, [tracker, defaultActor, defaultTarget, member, network, actor, target])

  return tracker
}
