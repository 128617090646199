import { useMemo } from 'react'

import { useLocation } from 'react-router-dom'

import { parseSearchParams } from '../../utils/url.utils.js'

export const useQuery = <T = Record<string, string>>(): T => {
  const { search } = useLocation()
  const result = useMemo(() => parseSearchParams<T>(search), [search])

  return result
}
