import { useRouter } from '@tribeplatform/react-sdk'
import { SystemSpaceSlug, useSpaceUtil } from '@tribeplatform/react-sdk/hooks'
import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { useI18n } from '../../i18n/providers/I18nProvider.js'

interface Props {
  onClick: () => void
}
export const NotificationSettingsLink = ({ onClick }: Props) => {
  const { Link } = useRouter()
  const { generatePath } = useSpaceUtil()
  const { $t } = useI18n()

  return (
    <IconButton
      as={Link}
      href={`${generatePath(SystemSpaceSlug.PROFILE_SETTINGS)}/notifications`}
      icon={<SvgIcon name="settings" />}
      variant="tertiaryNeutral"
      size="lg"
      accessibilityLabel={$t({
        defaultMessage: 'Notification settings',
        id: 'Generics.NotificationSetting',
      })}
      onClick={onClick}
    />
  )
}
