import { ErrorInfo } from 'react'

import { GraphqlClient } from '@tribeplatform/gql-client/client'
import {
  ApiErrorCodes,
  ClientError,
  ErrorResponse,
} from '@tribeplatform/gql-client/lib'
import { AuthToken } from '@tribeplatform/gql-client/types'
import { getDomainInfo } from '@tribeplatform/react-components/common/utils'

import { AppInitialProps } from '../../@types/app.types.js'

// This will hold a loading state for different errors to make sure we are not pursuing the same error multiple times
const ErrorHandlingLoadingStates = {}

const handleInvalidAccessToken = async (
  errors: ErrorResponse[],
  client: GraphqlClient,
  pageProps: AppInitialProps,
) => {
  try {
    const { getResourceUrl } = getDomainInfo(
      pageProps?.authToken?.networkPublicInfo,
    )

    const isInvalidToken =
      Array.isArray(errors) &&
      errors?.some(e => e.code === ApiErrorCodes.INVALID_ACCESS_TOKEN)

    if (
      isInvalidToken &&
      !ErrorHandlingLoadingStates[ApiErrorCodes.INVALID_ACCESS_TOKEN]
    ) {
      ErrorHandlingLoadingStates[ApiErrorCodes.INVALID_ACCESS_TOKEN] = true
      const networkDomain =
        pageProps?.authToken?.network?.domain ||
        pageProps?.authToken?.networkPublicInfo?.domain
      if (!networkDomain) {
        logger.warn('networkDomain is not defined on global error handler')
      }
      const { default: axios } = await import('axios')
      const response = await axios.post<AuthToken>(
        getResourceUrl('/api/auth/refresh-token'),
        {
          networkDomain,
        },
      )

      if (response?.data) {
        client.setToken(response.data.accessToken)
      }
      ErrorHandlingLoadingStates[ApiErrorCodes.INVALID_ACCESS_TOKEN] = false
    }
  } catch (e) {
    ErrorHandlingLoadingStates[ApiErrorCodes.INVALID_ACCESS_TOKEN] = false
    logger.warn('Could not update the access token on global error handler', e)
  }
}

export const onClientError = (
  errors: ErrorResponse[],
  client: GraphqlClient,
  pageProps: AppInitialProps,
  _error?: ClientError,
) => {
  handleInvalidAccessToken(errors, client, pageProps)
}

export const errorBoundaryHandler = (error: Error, errorInfo: ErrorInfo) => {
  logger.error(error, errorInfo)
}
