import { Plan, PlanName } from '@tribeplatform/gql-client/types'

import { I18nShape } from '../i18n/types.js'

export const hasPlan = (plan: Plan) => {
  return (
    plan &&
    plan?.name !== PlanName.basic &&
    plan?.name !== PlanName.Basic &&
    !plan?.trial
  )
}

const PlanOrder = {
  [PlanName.basic]: 0,
  [PlanName.Basic]: 0,
  [PlanName.plus]: 1,
  [PlanName.Plus]: 1,
  [PlanName.premium]: 2,
  [PlanName.Premium]: 2,
  [PlanName.Advanced]: 3,
  [PlanName.enterprise]: 4,
  [PlanName.Enterprise]: 4,
}

export const isHigherPlan = (currentPlan: PlanName, targetPlan: PlanName) => {
  if (!currentPlan || !targetPlan) {
    return false
  }
  return PlanOrder[currentPlan] >= PlanOrder[targetPlan]
}

export const getTranslatedPlanName = (
  { $t }: I18nShape,
  planName?: PlanName,
): string => {
  switch (planName) {
    case PlanName.Enterprise:
    case PlanName.enterprise:
      return $t({ defaultMessage: 'Enterprise', id: 'Plans.Enterprise.Name' })
    case PlanName.Advanced:
      return $t({ defaultMessage: 'Advanced', id: 'Plans.Advanced.Name' })
    // Temporarily hide premium and plus plans
    case PlanName.Premium:
    case PlanName.premium:
    case PlanName.PremiumLegacy:
    case PlanName.Plus:
    case PlanName.plus:
    case PlanName.PlusLegacy:
      return $t({ defaultMessage: 'Advanced', id: 'Plans.Advanced.Name' })
    case PlanName.basic:
    case PlanName.Basic:
      return $t({ defaultMessage: 'Free', id: 'Plans.Free.Name' })
    default:
      return $t({ defaultMessage: 'Free', id: 'Plans.Free.Name' })
  }
}
