import { FirebaseApp, initializeApp } from 'firebase/app'
import {
  Messaging,
  getMessaging,
  getToken,
  isSupported,
} from 'firebase/messaging'

import { RuntimeConfigs } from '../../../configs/runtime.js'

const firebaseConfig = {
  apiKey: RuntimeConfigs.FIREBASE_API_KEY,
  authDomain: RuntimeConfigs.FIREBASE_AUTH_DOMAIN,
  projectId: RuntimeConfigs.FIREBASE_PROJECT_ID,
  storageBucket: RuntimeConfigs.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: RuntimeConfigs.FIREBASE_MESSAGING_SENDER_ID,
  appId: RuntimeConfigs.FIREBASE_APP_ID,
  measurementId: RuntimeConfigs.FIREBASE_MEASUREMENT_ID,
}

const vapidKey = RuntimeConfigs.FIREBASE_VAPID_KEY

export const getFirebaseApp = (): FirebaseApp => {
  try {
    if (!isSupported()) {
      return undefined
    }

    return initializeApp(firebaseConfig)
  } catch (error) {
    logger.error(error)
    return undefined
  }
}

export const getFirebaseMessaging = (app: FirebaseApp): Messaging => {
  if (!app) {
    return undefined
  }

  try {
    return getMessaging(app)
  } catch (error) {
    logger.error(error)
    return undefined
  }
}

export const getFcmToken = async (
  messaging: Messaging,
  getResourceUrl: (path: string) => string,
): Promise<string | null> => {
  if (!messaging) {
    return null
  }

  try {
    const swRegistration = await registerServiceWorker(getResourceUrl)
    const token = await getToken(messaging, {
      vapidKey,
      serviceWorkerRegistration: swRegistration,
    })

    return token
  } catch (error) {
    logger.error(error)
    return null
  }
}

/**
 * Registers the service worker for Firebase messaging if not already registered.
 * There seems to be an issue with the Firebase service worker registration that
 * causes the service worker to not be registered on the first load.
 * https://github.com/firebase/firebase-js-sdk/issues/7784
 */
const registerServiceWorker = async (
  getResourceUrl: (path: string) => string,
) => {
  if (!('serviceWorker' in navigator)) {
    return undefined
  }

  try {
    const workers = await navigator.serviceWorker.getRegistrations()
    const firebaseWorker = workers.find(worker =>
      worker.active.scriptURL.includes('firebase-messaging-sw.js'),
    )

    if (firebaseWorker) {
      return firebaseWorker
    }

    const registration = await navigator.serviceWorker.register(
      getResourceUrl('/firebase-messaging-sw.js'),
    )

    return registration
  } catch (error) {
    return undefined
  }
}
