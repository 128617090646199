import { createContext, useCallback, useEffect, useRef, useState } from 'react'

import { TribeClient } from '@tribeplatform/gql-client/client'
import type {
  AuthToken,
  QueryTokensArgs,
} from '@tribeplatform/gql-client/types'

import type {
  BetaClientContextProps,
  ClientContextProps,
  ClientProviderType,
  TribeClientConfig,
} from './@types/index.js'
import { getTokens } from './lib/getTokens.js'
import { defaultTracker, TribeTracker } from './TrackerProvider.js'

export const ClientContext = createContext<
  ClientContextProps & BetaClientContextProps
>({
  client: null,
  ready: undefined,
  boot: null,
  config: null,

  betaClient: null,
  betaReady: undefined,
  betaBoot: null,
  betaConfig: null,
})

const SSR = typeof window === 'undefined'

export const ClientProvider: ClientProviderType = ({
  children,
  config,
  autoBoot = true,
  onTokenUpdate,
}) => {
  const {
    client: betaClient,
    ready: betaReady,
    boot: betaBoot,
    config: betaConfig,
  } = useCreateBetaClientInternal({
    config,
    autoBoot,
  })
  const { accessToken, baseUrl: graphqlUrl, gatewayCacheControl } = config
  const loadingRef = useRef<boolean>(false)

  const [client] = useState<TribeClient>(
    new TribeClient({
      accessToken,
      graphqlUrl,
      onError: config.onError,
      onEvent: config.onEvent,
      notifyOnTokenExpiration: !SSR,
      gatewayCacheControl,
      /**
       * By default graphql-client uses cross-fetch which creates compatibility issues with
       * Node 19+. Passing default node fetch to resolve this issue in server.
       * Upgrading to graphql-client v6 resolves this issue but introduces other breaking changes.
       * See: https://github.com/jasonkuhrt/graphql-request/issues/628
       */
      fetch: SSR ? fetch : undefined,
    }),
  )

  const ready = !!client.accessToken
  const update = useCallback(
    async (input: QueryTokensArgs) => {
      await getTokens(client, input)
      loadingRef.current = false
    },
    [client],
  )

  useEffect(() => {
    if (autoBoot && !loadingRef.current && !ready) {
      loadingRef.current = true
      update(config)
    }
  }, [loadingRef, update, client, ready, config, autoBoot])

  const boot = () => {
    update(config)
  }

  return (
    <ClientContext.Provider
      value={{
        client,
        ready,
        boot,
        config,
        betaClient,
        betaReady,
        betaBoot,
        betaConfig,
        onTokenUpdate: (token: AuthToken) => {
          if (token?.accessToken) {
            client?.setToken(token.accessToken)
            betaClient?.setToken(token.accessToken)
          }
          if (onTokenUpdate && typeof onTokenUpdate === 'function') {
            onTokenUpdate(token)
          }
        },
      }}
    >
      <TribeTracker value={config.tracker || defaultTracker}>
        {children}
      </TribeTracker>
    </ClientContext.Provider>
  )
}

const useCreateBetaClientInternal = (options: {
  config: TribeClientConfig
  autoBoot?: boolean
}): {
  client: TribeClient
  config: TribeClientConfig
  ready: boolean
  boot: () => void
} => {
  const { config, autoBoot } = options

  const { accessToken, betaBaseUrl: graphqlUrl, gatewayCacheControl } = config
  const loadingRef = useRef<boolean>(false)

  const [client] = useState<TribeClient>(
    new TribeClient({
      accessToken,
      graphqlUrl,
      onError: config.onError,
      onEvent: config.onEvent,
      notifyOnTokenExpiration: !SSR,
      gatewayCacheControl,
      /**
       * By default graphql-client uses cross-fetch which creates compatibility issues with
       * Node 19+. Passing default node fetch to resolve this issue in server.
       * Upgrading to graphql-client v6 resolves this issue but introduces other breaking changes.
       * See: https://github.com/jasonkuhrt/graphql-request/issues/628
       */
      fetch: SSR ? fetch : undefined,
    }),
  )

  const ready = !!client.accessToken
  const update = useCallback(
    async (input: QueryTokensArgs) => {
      await getTokens(client, input)
      loadingRef.current = false
    },
    [client],
  )

  useEffect(() => {
    if (autoBoot && !loadingRef.current && !ready) {
      loadingRef.current = true
      update(config)
    }
  }, [loadingRef, update, client, ready, config, autoBoot])

  const boot = () => {
    update(config)
  }

  return { client, ready, boot, config }
}
