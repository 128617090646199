import { cva } from 'class-variance-authority'

export const toggleStyles = cva(
  [
    'relative inline-flex items-center rounded-full ',
    'border border-line',
    'cursor-pointer',
    'transition ease-in-out duration-200',
    'disabled:cursor-not-allowed',
    'disabled:bg-interactive-disabled aria-checked:disabled:bg-interactive-disabled',
    'disabled:border-line-disabled',
    'focus:outline-none focus-visible:ring',
    'focus-visible:ring-interactive',
    'bg-surface-subdued hover:bg-surface-subdued-hovered',
    'aria-checked:bg-interactive aria-checked:hover:bg-interactive-hovered',
    'aria-checked:border-interactive aria-checked:hover:border-interactive-hovered aria-checked:disabled:border-line-disabled',
    'flex-none',
  ],
  {
    variants: {
      size: {
        md: 'h-6 w-11',
        sm: 'h-5 w-9',
        xs: 'h-4 w-7',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
)

export const toggleThumbStyles = cva(
  [
    'pointer-events-none block rounded-full',
    'transition-transform duration-100 will-change-transform',
  ],
  {
    variants: {
      checked: {
        false: 'translate-x-0.5',
      },
      disabled: {
        true: 'bg-line-disabled',
        false: 'bg-content-on-interactive shadow-sm',
      },
      size: {
        md: 'h-[18px] w-[18px]',
        sm: 'h-[14px] w-[14px]',
        xs: 'h-[10px] w-[10px]',
      },
    },
    defaultVariants: {
      size: 'md',
      checked: false,
    },
    compoundVariants: [
      {
        checked: true,
        size: 'md',
        class: 'translate-x-[22px] rtl:-translate-x-[22px]',
      },
      {
        checked: true,
        size: 'sm',
        class: 'translate-x-[18px] rtl:-translate-x-[18px]',
      },
      {
        checked: true,
        size: 'xs',
        class: 'translate-x-[14px] rtl:-translate-x-[14px]',
      },
    ],
  },
)
