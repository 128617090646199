import { GlyphMediaVariant } from '@tribeplatform/gql-client/types'
import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { StaticField } from './Fields/types.js'
import {
  SpaceHeaderFieldKey,
  SpaceHeaderIconSize,
  ActionButtonVariant,
  MembersAvatarSize,
  IconWrapperStyle,
} from './types.js'

export const staticFieldsFactory: (intl: I18nShape) => StaticField[] = ({
  $t,
}) => [
  {
    id: 'Icon',
    enabled: true,
    key: SpaceHeaderFieldKey.Icon,
    locked: true,
    label: $t({
      id: 'Generics.Icon',
      defaultMessage: 'Icon',
    }),
    icon: <SvgIcon name="image" />,
    settings: {
      stretchX: true,
      size: SpaceHeaderIconSize.Large,
      iconWrapperStyle: IconWrapperStyle.Circle,
      useSpaceIcon: true,
      customIcon: {
        id: 'emoji/1f508',
        text: '1f508',
        variant: GlyphMediaVariant.emoji,
      },
    },
  },
  {
    id: 'Title',
    key: SpaceHeaderFieldKey.Title,
    enabled: true,
    locked: false,
    label: $t({
      id: 'Generics.Title',
      defaultMessage: 'Title',
    }),
    icon: <SvgIcon name="h-1" />,
    settings: {
      stretchX: true,
      customTitle: $t({
        id: 'Blocks.SpaceHeader.CustomTitlePlaceholder',
        defaultMessage: "Welcome to '{{ space.name }}'",
      }),
      useSpaceName: true,
      showPrivateIcon: true,
      size: 'headingLg',
    },
  },
  {
    id: 'Description',
    key: SpaceHeaderFieldKey.Description,
    enabled: true,
    locked: false,
    label: $t({
      id: 'Generics.Description',
      defaultMessage: 'Description',
    }),
    icon: <SvgIcon name="field-text" />,
    settings: {
      stretchX: true,
      customDescription: $t({
        id: 'Blocks.SpaceHeader.CustomDescriptionPlaceholder',
        defaultMessage: 'Description goes here...',
      }),
      useSpaceDescription: true,
    },
  },
  {
    enabled: false,
    id: 'Stats',
    key: SpaceHeaderFieldKey.Stats,
    locked: false,
    label: $t({
      id: 'Generics.Stats',
      defaultMessage: 'Stats',
    }),
    icon: <SvgIcon name="chart" />,
    settings: {
      stretchX: true,
    },
    fields: [
      {
        enabled: true,
        id: 'TotalMembers',
        key: SpaceHeaderFieldKey.TotalMembers,
        locked: false,
        label: $t({
          id: 'Blocks.SpaceHeader.Fields.TotalMembers',
          defaultMessage: 'Total members',
        }),
        icon: <SvgIcon name="members" />,
        settings: {
          isSubField: true,
          stretchX: false,
          showText: true,
        },
      },
      {
        enabled: true,
        id: 'TotalPosts',
        key: SpaceHeaderFieldKey.TotalPosts,
        locked: false,
        label: $t({
          id: 'Blocks.SpaceHeader.Fields.TotalPosts',
          defaultMessage: 'Total posts',
        }),
        icon: <SvgIcon name="posts" />,
        settings: {
          isSubField: true,
          stretchX: false,
          showText: true,
        },
      },
      {
        enabled: false,
        id: 'DateCreated',
        key: SpaceHeaderFieldKey.DateCreated,
        locked: false,
        label: $t({
          id: 'Generics.DateCreated',
          defaultMessage: 'Date created',
        }),
        icon: <SvgIcon name="calendar" />,
        settings: {
          isSubField: true,
          stretchX: false,
          format: 'relative',
        },
      },
    ],
  },
  {
    enabled: false,
    id: 'Members',
    key: SpaceHeaderFieldKey.Members,
    locked: false,
    label: $t({
      id: 'Generics.Members',
      defaultMessage: '{MEMBERS_CC}',
    }),
    icon: <SvgIcon name="members" />,
    settings: {
      stretchX: true,
      showCount: true,
      size: MembersAvatarSize.Small,
    },
  },
  {
    enabled: false,
    id: 'Buttons',
    key: SpaceHeaderFieldKey.Buttons,
    locked: false,
    label: $t({
      id: 'Generics.Buttons',
      defaultMessage: 'Buttons',
    }),
    icon: <SvgIcon name="cursor-click" />,
    fields: [
      {
        enabled: true,
        id: 'AddPostButton',
        key: SpaceHeaderFieldKey.AddPostButton,
        locked: false,
        label: $t({
          id: 'Generics.AddPost',
          defaultMessage: 'Add post',
        }),
        icon: <SvgIcon name="plus-circle" />,
        settings: {
          stretchX: true,
          isSubField: true,
        },
      },
      {
        enabled: true,
        id: 'JoinButton',
        key: SpaceHeaderFieldKey.JoinButton,
        locked: false,
        label: $t({
          id: 'Generics.Join',
          defaultMessage: 'Join',
        }),
        icon: <SvgIcon name="check-circle-broken" />,
        settings: {
          stretchX: true,
          isSubField: true,
          variant: ActionButtonVariant.Text,
        },
      },
      {
        enabled: true,
        id: 'NotificationsButton',
        key: SpaceHeaderFieldKey.NotificationsButton,
        locked: false,
        label: $t({
          id: 'Generics.Notifications',
          defaultMessage: 'Notifications',
        }),
        icon: <SvgIcon name="bell" />,
        settings: {
          stretchX: true,
          isSubField: true,
          variant: ActionButtonVariant.Icon,
        },
      },
      {
        enabled: true,
        id: 'SearchButton',
        key: SpaceHeaderFieldKey.SearchButton,
        locked: false,
        label: $t({
          id: 'Generics.Search',
          defaultMessage: 'Search',
        }),
        icon: <SvgIcon name="search" />,
        settings: {
          stretchX: true,
          isSubField: true,
          variant: ActionButtonVariant.Icon,
          icon: {
            id: 'icon/search-md',
            text: 'search-md',
            variant: GlyphMediaVariant.icon,
          },
        },
      },
      {
        enabled: true,
        id: 'ShareButton',
        key: SpaceHeaderFieldKey.ShareButton,
        locked: false,
        label: $t({
          id: 'Generics.Share',
          defaultMessage: 'Share',
        }),
        icon: <SvgIcon name="share" />,
        settings: {
          stretchX: true,
          isSubField: true,
          variant: ActionButtonVariant.Text,
          icon: {
            id: 'icon/share-06',
            text: 'share-06',
            variant: GlyphMediaVariant.icon,
          },
        },
      },
      {
        enabled: true,
        id: 'OptionsButton',
        key: SpaceHeaderFieldKey.OptionsButton,
        locked: false,
        label: $t({
          id: 'Generics.Options',
          defaultMessage: 'Options',
        }),
        icon: <SvgIcon name="selector" />,
        settings: {
          stretchX: true,
          isSubField: true,
          variant: ActionButtonVariant.Icon,
        },
      },
    ],
    settings: {
      stretchX: true,
    },
  },
]
