import { useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { Member, Role } from '@tribeplatform/gql-client/types'

import {
  generateMemberVerificationUrl,
  isUnconfirmedMember,
  pathMayRequireAuthorization,
} from '../../utils/permission.utils.js'

export const useRedirectUnconfirmedUser = (
  member: Member,
  role: Role,
): void => {
  const { search, pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (
      isUnconfirmedMember(member, role) &&
      pathMayRequireAuthorization(pathname)
    ) {
      navigate(generateMemberVerificationUrl(member.id, `${pathname}${search}`))
    }
  }, [pathname, search, member, role, navigate])
}
