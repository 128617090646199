import { useContext } from 'react'

import { ClientContext } from './ClientProvider.js'

import { ClientContextProps } from './index.js'

export const useTribeClient = (options?: {
  useBetaClient?: boolean
}): ClientContextProps => {
  const { useBetaClient: useBetaClientInput = false } = options || {}
  const context = useContext(ClientContext)
  const {
    config: { shouldUseBetaClient },
  } = context
  const useBetaClient = useBetaClientInput ? shouldUseBetaClient : false
  return {
    ...context,
    client: useBetaClient ? context.betaClient : context.client,
    ready: useBetaClient ? context.betaReady : context.ready,
    boot: useBetaClient ? context.betaBoot : context.boot,
    config: useBetaClient ? context.betaConfig : context.config,
  }
}
