import { useEffect, useState } from 'react'

import { ActionStatus } from '@tribeplatform/gql-client/types'
import { useUpdateSubscription } from '@tribeplatform/react-sdk/hooks'
import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { useDomain } from '../common/hooks/useDomain.js'
import { useLogin } from '../common/hooks/useLogin.js'
import { useI18n } from '../i18n/index.js'
import { getIconSpriteUrl } from '../Picker/index.js'

interface Props {
  publisherType: 'space' | 'post' | 'member'
  publisherId: string
  subscribed: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

export const SubscriptionBell = ({
  publisherType,
  publisherId,
  subscribed,
  size = 'lg',
}: Props) => {
  const { $t } = useI18n()
  const { isLoggedIn, showLogin } = useLogin()
  const { subfolder } = useDomain()

  const { mutateAsync: updateSubscription } = useUpdateSubscription()
  const [optimisticSubscribed, setOptimisticSubscribed] =
    useState<boolean>(subscribed)

  useEffect(() => {
    setOptimisticSubscribed(subscribed)
  }, [subscribed])

  const onBellClick = async () => {
    if (!isLoggedIn) {
      showLogin()
      return
    }

    const isSubscribing = !optimisticSubscribed
    setOptimisticSubscribed(isSubscribing)
    try {
      await updateSubscription(
        {
          action: isSubscribing ? 'subscribe' : 'unsubscribe',
          publisherType,
          publisherId,
        },
        {
          onSuccess: ({ status }) => {
            if (isSubscribing && status === ActionStatus.succeeded) {
              toast({
                title: $t({
                  defaultMessage:
                    'You’re now receiving notifications for new updates!',
                  id: 'Settings.Notification.Subscribed',
                }),
                status: 'success',
              })
            } else if (status === ActionStatus.failed) {
              setOptimisticSubscribed(!isSubscribing)
            }
          },
        },
      )
    } catch (error) {
      setOptimisticSubscribed(!isSubscribing)
    }
  }

  return (
    <IconButton
      variant="secondaryNeutral"
      pressed={optimisticSubscribed}
      onClick={onBellClick}
      icon={
        <SvgIcon
          iconSpriteUrl={getIconSpriteUrl(
            optimisticSubscribed ? 'solid' : 'line',
            { subfolder },
          )}
          name={optimisticSubscribed ? 'bell-ringing-01' : 'bell-plus'}
        />
      }
      size={size}
    />
  )
}
