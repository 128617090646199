import { useMemo } from 'react'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { useI18n } from '@tribeplatform/react-components/i18n'
import {
  SystemSpaceSlug,
  useAuthToken,
  useLogoutAndRedirect,
  useModerationCount,
  useSpaceUtil,
} from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { DropDownItemType, type DropDownItem } from '../types.js'

interface UseMainMenuItemsProps {
  setOpenMemberInviteModal: (open: boolean) => void
}
export const useMainMenuItems = ({
  setOpenMemberInviteModal,
}: UseMainMenuItemsProps) => {
  const { $t } = useI18n()
  const {
    data: { network },
  } = useAuthToken()
  const { logout } = useLogoutAndRedirect()
  const { generatePath, generateProfilePath } = useSpaceUtil()
  const accountSettingsPath = generatePath(SystemSpaceSlug.PROFILE_SETTINGS)
  const [canUpdateNetwork] = hasScopesPermission(network, ['updateNetwork'])
  const [canGetModerations] = hasScopesPermission(network, ['getModerations'])
  const [canInviteMembers] = hasScopesPermission(network, ['inviteMembers'])
  const { total: totalModerationItems } = useModerationCount()

  const mainMenuItems = useMemo<DropDownItem[]>(() => {
    const generalItems: DropDownItem[] = [
      {
        type: DropDownItemType.regular,
        id: 'yourProfile',
        label: $t({
          defaultMessage: 'Your profile',
          id: 'Navbar.Controls.YourProfile',
        }),
        href: generateProfilePath(),
        icon: <SvgIcon name="member" />,
      },
      {
        type: DropDownItemType.regular,
        id: 'accountSettings',
        label: $t({
          defaultMessage: 'Account settings',
          id: 'Navbar.Controls.AccountSettings',
        }),
        href: `${accountSettingsPath}/account`,
        icon: <SvgIcon name="account-setting" />,
      },
    ]
    const administrationItems: DropDownItem[] = [
      {
        type: DropDownItemType.divider,
        id: 'administrationDivider',
      },
      {
        type: DropDownItemType.regular,
        id: 'administration',
        label: $t({
          defaultMessage: 'Administration',
          id: 'Generics.Administration',
        }),
        href: '/manage/people/members',
        icon: <SvgIcon name="settings" />,
      },
    ]
    const moderationItems: DropDownItem[] = [
      {
        type: DropDownItemType.regular,
        id: 'moderation',
        label: $t({
          defaultMessage: 'Moderation',
          id: 'Generics.Moderation',
        }),
        href: '/settings/moderation',
        icon: <SvgIcon name="moderation" />,
        indicator: totalModerationItems,
      },
    ]
    const inviteMembersItem: DropDownItem[] = [
      {
        type: DropDownItemType.regular,
        id: 'inviteMembers',
        label: $t({
          defaultMessage: 'Invite {MEMBERS}',
          id: 'Navbar.Controls.AddMembers',
        }),
        icon: <SvgIcon name="member-add" />,
        action: () => setOpenMemberInviteModal(true),
      },
    ]
    const signOutItems: DropDownItem[] = [
      {
        type: DropDownItemType.divider,
        id: 'signOutDivider',
      },
      {
        type: DropDownItemType.regular,
        id: 'signOut',
        label: $t({
          defaultMessage: 'Log out',
          id: 'Generics.LogOut',
        }),
        action: logout,
        icon: <SvgIcon name="logout" />,
      },
    ]
    return [
      ...generalItems,
      ...(canInviteMembers ? inviteMembersItem : []),
      ...(canUpdateNetwork ? administrationItems : []),
      ...(canGetModerations ? moderationItems : []),
      ...signOutItems,
    ]
  }, [
    $t,
    accountSettingsPath,
    canGetModerations,
    canInviteMembers,
    canUpdateNetwork,
    generateProfilePath,
    logout,
    setOpenMemberInviteModal,
    totalModerationItems,
  ])

  return mainMenuItems
}
