import { type PathMatch } from 'react-router'

import {
  convertLegacyMatchToPathMatch,
  legacyMatchPath,
} from '@bettermode/common/legacy-match-path'
import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Member, Post, RoleType, Space } from '@tribeplatform/gql-client/types'

import {
  generateMemberPath as dumbGenerateMemberPath,
  generatePath as dumbGeneratePath,
  generatePostPath as dumbGeneratePostPath,
} from '../../utils/space.utils.js'
import { useAuthToken } from '../auth/useAuthToken.js'

export enum SystemSpaceSlug {
  LOGIN = 'default::login',
  DIRECT_LOGIN = 'default::direct-login',
  SIGNUP = 'default::signup',
  JOIN_BY_INVITATION = 'default::join-with-invite',
  ACCEPT_INVITATION = 'default::accept',
  FORGOT_PASSWORD = 'default::forgot-password',
  RESET_PASSWORD = 'default::reset-password',
  VERIFY_EMAIL = 'default::verify',
  MEMBER = 'default::member',
  POST = 'default::post',
  COLLECTION = 'default::collection',
  SEARCH = 'default::search',
  NOTIFICATIONS = 'default::notifications',
  PROFILE_SETTINGS = 'default::profile-settings',
  NOT_FOUND = 'default::not-found',
  HOME = 'default::home',
  MEMBERS = 'default::members',
  SPACES = 'default::spaces',
}

export type SpacePathGenerator = (
  spaceOrSlug: Partial<Space> | SystemSpaceSlug,
  params?: Record<string, string>,
  queryParams?: Record<string, string>,
) => string

export type PostPathGenerator = (
  space: Partial<Space>,
  post: Partial<Post>,
  reply?: Partial<Post>,
) => string

export const spaceQueriesMapping = {
  [SystemSpaceSlug.LOGIN]: {
    query: 'loginNetwork',
    authorizedRoles: [RoleType.guest],
  },
  [SystemSpaceSlug.SIGNUP]: {
    query: 'joinNetwork',
    authorizedRoles: [RoleType.guest],
  },
  [SystemSpaceSlug.SEARCH]: {
    query: 'search',
  },
  [SystemSpaceSlug.NOTIFICATIONS]: {
    query: 'notifications',
  },
  [SystemSpaceSlug.COLLECTION]: {
    query: 'collections',
  },
  [SystemSpaceSlug.MEMBER]: {
    query: 'authMember',
  },
}

export type SpaceUtilResult = {
  generatePath: SpacePathGenerator
  generateProfilePath: () => string
  generateMemberPath: (member: Partial<Member>) => string
  generatePostPath: PostPathGenerator
  matchPath: (
    spaceOrSlug: Partial<Space> | SystemSpaceSlug,
    path: string,
    params?: Record<string, string>,
  ) => PathMatch
  hasViewPermission: (slug: SystemSpaceSlug | string) => boolean
}

export const useSpaceUtil = (): SpaceUtilResult => {
  const { data } = useAuthToken({
    useQueryOptions: {
      // this is here to prevent the whole page from re-rendering
      notifyOnChangeProps: ['data'],
    },
  })
  const { network, member, role } = data || {}
  const { systemSpaces } = network || {}

  const spaceScopes = Object.keys(spaceQueriesMapping).map(key => [
    key,
    spaceQueriesMapping[key],
  ])
  const permissions = hasScopesPermission(
    network,
    spaceScopes.map(p => p[1].query),
  )
  const spacePermissions: Record<string, boolean> = permissions
    .map((queryAuthorized, idx) => {
      let authorized = queryAuthorized
      const spaceMapping = spaceScopes[idx][1]
      if (
        spaceMapping?.authorizedRoles &&
        !spaceMapping?.authorizedRoles?.includes(role?.type)
      ) {
        authorized = false
      }
      return { [spaceScopes[idx][0]]: authorized }
    })
    .reduce((preValue, value) => ({ ...preValue, ...value }), {})

  const findOne = (slug: SystemSpaceSlug) =>
    systemSpaces?.find(space => space.slug === slug)
  const generatePath = (
    spaceOrSlug: Partial<Space> | SystemSpaceSlug,
    params?: Record<string, string>,
    queryParams?: Record<string, string>,
  ): string => {
    const space =
      typeof spaceOrSlug === 'string' ? findOne(spaceOrSlug) : spaceOrSlug
    return dumbGeneratePath(space, params, queryParams).replace(/\/\//g, '/')
  }
  return {
    generatePath,
    generateProfilePath: () => {
      const memberSpace = findOne(SystemSpaceSlug.MEMBER)
      return dumbGenerateMemberPath(memberSpace, member)
    },
    generateMemberPath: (member: Partial<Member>) => {
      const memberSpace = findOne(SystemSpaceSlug.MEMBER)
      return dumbGenerateMemberPath(memberSpace, member)
    },
    generatePostPath: (
      space: Partial<Space>,
      post: Partial<Post>,
      reply?: Partial<Post>,
    ) => {
      const postSpace = findOne(SystemSpaceSlug.POST)
      return dumbGeneratePostPath(postSpace, space, post, reply)
    },
    matchPath: (
      spaceOrSlug: Partial<Space> | SystemSpaceSlug,
      path: string,
      params?: Record<string, string>,
    ): PathMatch | null => {
      if (!spaceOrSlug) {
        return null
      }
      if (path === null || path === undefined) {
        return null
      }

      const space =
        typeof spaceOrSlug === 'string' ? findOne(spaceOrSlug) : spaceOrSlug
      const spacePath = generatePath(spaceOrSlug, params).replace(
        /^\/+|\/+$/g,
        '',
      )
      const firstPath = path.replace(/^\/+|\/+$/g, '')
      const secondaryPath = `/${firstPath}`
      const legacyResult =
        legacyMatchPath(firstPath, {
          path: spacePath,
          exact: space?.address?.exact,
          strict: false,
        }) ||
        legacyMatchPath(secondaryPath, {
          path: spacePath,
          exact: space?.address?.exact,
          strict: false,
        })

      if (!space && !spacePath) {
        return null
      }

      if (legacyResult === null) {
        return null
      }
      return convertLegacyMatchToPathMatch(legacyResult)
    },
    hasViewPermission: (slug: SystemSpaceSlug | string) =>
      !(spacePermissions[slug] === false),
  }
}
