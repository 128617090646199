import type { SpaceFields } from '@tribeplatform/gql-client/graphql'
import type {
  PaginatedSpace,
  QuerySpacesArgs,
} from '@tribeplatform/gql-client/types'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '../../lib/react-query/useInfiniteQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpacesKey } from '../../utils/keys/space.key.js'

export const DefaultSpacesLimit = 10

export const useSpaces = (options?: {
  variables?: QuerySpacesArgs
  fields?: SpaceFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedSpace>
}) => {
  const {
    variables = { limit: DefaultSpacesLimit },
    fields = 'basic',
    useInfiniteQueryOptions,
  } = options || {}
  const { client } = useTribeClient({ useBetaClient: true })
  const spacesKey = getSpacesKey({ variables, fields })

  return useInfiniteQuery<PaginatedSpace>(
    spacesKey,
    ({ pageParam: after }) =>
      client?.spaces.list({ after, ...variables }, fields),
    useInfiniteQueryOptions,
  )
}
