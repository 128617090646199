import {
  toI18nMessageId,
  useI18n,
  useStaticIntl,
} from '@tribeplatform/react-components/i18n'
import { useRouter, useRouterPath } from '@tribeplatform/react-sdk'
import {
  SystemSpaceSlug,
  useAuthMember,
  useSpaceUtil,
} from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'

import { useSlate } from '../../hooks/slate.hook.js'
import { BC } from '../../types/index.js'
import { useMobileMenu } from '../Navbar/providers/MobileMenuProvider.js'
import { mainMenuIconNames } from './constants.js'
import { MainMenuAction, MainMenuProps } from './types.js'
import { getDeprecatedItemsCleaner } from './utils.js'

export const MainMenuBlock: BC<MainMenuProps> = ({
  items: _items,
  ...rest
}) => {
  const { getMessageByDynamicId } = useI18n()
  const { Link } = useRouter()
  const { close } = useMobileMenu()
  const pathname = useRouterPath()
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'

  const { data: authMember } = useAuthMember()
  const { generateProfilePath, matchPath, hasViewPermission } = useSpaceUtil()
  const deprecatedItemsCleaner = useStaticIntl(getDeprecatedItemsCleaner)

  const items = deprecatedItemsCleaner(_items)

  const pathMapping = {
    [SystemSpaceSlug.HOME]: '/',
    [SystemSpaceSlug.MEMBERS]: '/members',
    [SystemSpaceSlug.SPACES]: '/spaces',
    [SystemSpaceSlug.NOTIFICATIONS]: '/notifications',
    [SystemSpaceSlug.SEARCH]: '/search',
  }

  return (
    <NavVertical {...rest}>
      <NavVertical.Group>
        {items
          .map(item => {
            if (!item.enabled) {
              return null
            }
            const iconName = mainMenuIconNames[item.icon]
            let matched
            let isActive: boolean

            const spacePath = pathMapping[item?.spaceSlug] || '/'

            const labelText = getMessageByDynamicId(
              toI18nMessageId('Navigation', item.label),
              item.label,
            )

            switch (item.action) {
              case MainMenuAction.OPEN_LINK:
                return (
                  <NavVertical.Item
                    key={item.label}
                    as={Link}
                    variant="inherit"
                    href={item.link}
                    active={false}
                    leadingIcon={<SvgIcon name={iconName} />}
                    target="_blank"
                    onClick={e => {
                      if (isPreviewMode) {
                        e.preventDefault()
                        return
                      }

                      close()
                    }}
                  >
                    {labelText}
                  </NavVertical.Item>
                )
              case MainMenuAction.OPEN_PROFILE:
                if (!hasViewPermission(SystemSpaceSlug.MEMBER)) {
                  return null
                }
                matched = matchPath(SystemSpaceSlug.MEMBER, pathname)
                isActive = matched && matched?.params?.id === authMember?.id
                return (
                  <NavVertical.Item
                    key={item.label}
                    as={Link}
                    variant="inherit"
                    href={generateProfilePath()}
                    active={isActive}
                    leadingIcon={<SvgIcon name={iconName} />}
                    onClick={e => {
                      if (isPreviewMode) {
                        e.preventDefault()
                        return
                      }

                      close()
                    }}
                  >
                    {labelText}
                  </NavVertical.Item>
                )
              case MainMenuAction.OPEN_SPACE:
                if (!spacePath) {
                  return null
                }
                matched = spacePath === pathname
                return (
                  <NavVertical.Item
                    key={item.label}
                    as={Link}
                    variant="inherit"
                    href={spacePath}
                    active={!!matched}
                    leadingIcon={<SvgIcon name={iconName} />}
                    onClick={e => {
                      if (isPreviewMode) {
                        e.preventDefault()
                        return
                      }

                      close()
                    }}
                  >
                    {labelText}
                  </NavVertical.Item>
                )
              default:
                return null
            }
          })
          .filter(item => item)}
      </NavVertical.Group>
    </NavVertical>
  )
}
