import {
  CustomFieldPrivacyOptions,
  CustomFieldSettings,
  CustomFieldValidators,
  ReactionType,
  type CustomFieldPrivacy,
  type CustomFieldSchema,
  type CustomFieldValidatorInput,
  type BaseCustomFieldSchema,
  type PostType,
  type CustomReaction,
} from '@tribeplatform/gql-client/types'
import { noop } from '@tribeplatform/react-ui-kit/hooks'
import {
  colorStylesFactory,
  randomColor,
  type ColoredLabel,
} from '@tribeplatform/react-ui-kit/LabelPicker'

import { logger } from '../../common/lib/logger.js'
import { CustomFieldSubtype } from '../../CustomField/utils.js'
import { CONTENT_FIELD_KEY, TITLE_FIELD_KEY } from '../constants.js'
import {
  ReadOptions,
  WriteOptions,
  type FieldItem,
  type SelectOption,
} from '../types.js'
import {
  legacyEmojiReactions,
  legacyLikeReactions,
  legacyVoteReactions,
} from './legacyReactions.js'

export const sanitizePostType = (postType: PostType): PostType => {
  if (!postType) {
    return null
  }

  const { primaryReactionType, allowedReactions, customReactions } = postType

  if (customReactions !== null || allowedReactions !== null) {
    return postType
  }

  const newCustomReactions = getLegacyCustomReactions(primaryReactionType)
  const newAllowedReactions = newCustomReactions.map(({ key }) => key)
  const newSingleChoiceReactions = newCustomReactions.map(({ key }) => key)
  const newPostType: PostType = {
    ...postType,
    allowedReactions: newAllowedReactions,
    customReactions: newCustomReactions,
    singleChoiceReactions: newSingleChoiceReactions,
  }

  return newPostType
}

const getLegacyCustomReactions = (
  primaryReactionType: ReactionType,
): CustomReaction[] => {
  switch (primaryReactionType) {
    case ReactionType.EMOJI_BASE:
      return legacyEmojiReactions
    case ReactionType.LIKE_BASE:
      return legacyLikeReactions
    case ReactionType.VOTE_BASE:
      return legacyVoteReactions
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = primaryReactionType
      noop(exhaustiveCheck)
      return []
    }
  }
}

type GetFieldSetting = <T>(field: CustomFieldSchema, key: string) => T
export const getFieldSetting: GetFieldSetting = (field, key) => {
  const { settings } = field

  if (!settings) {
    return null
  }

  const setting = settings.find(setting => setting.key === key)

  if (!setting) {
    return null
  }

  const { value } = setting

  if (!value) {
    return null
  }

  try {
    return JSON.parse(value)
  } catch (err) {
    logger.error('Cannot parse value in custom field')
    return null
  }
}

export const convertToCustomFields = (
  fieldItems: Array<CustomFieldSchema & FieldItem>,
): CustomFieldSchema[] => {
  const customFields: CustomFieldSchema[] = fieldItems.map(fieldItem => {
    const {
      id: _id,
      label: _label,
      icon: _icon,
      enabled: _enabled,
      isPrivate: _isPrivate,
      draggable: _draggable,
      isLocked: _isLocked,
      ...customField
    } = fieldItem

    return customField
  })

  return customFields
}

export const isFieldLocked = (key: string) => {
  if (key === TITLE_FIELD_KEY || key === CONTENT_FIELD_KEY) {
    return true
  }

  return false
}

export const isFieldPrivate = (readPrivacy: CustomFieldPrivacy) => {
  return readPrivacy?.allow?.length > 0
}

export const getReadPrivacyValue = (
  readOption: ReadOptions,
): CustomFieldPrivacyOptions[] => {
  if (readOption === ReadOptions.admin) {
    return [CustomFieldPrivacyOptions.ADMIN]
  }

  if (readOption === ReadOptions.own) {
    return [CustomFieldPrivacyOptions.OWN, CustomFieldPrivacyOptions.ADMIN]
  }

  return []
}

export const getWritePrivacyValue = (
  readOption: WriteOptions,
): CustomFieldPrivacyOptions[] => {
  if (readOption === WriteOptions.admin) {
    return [CustomFieldPrivacyOptions.ADMIN]
  }

  if (readOption === WriteOptions.own) {
    return [CustomFieldPrivacyOptions.OWN, CustomFieldPrivacyOptions.ADMIN]
  }

  return []
}

export const getReadPermissionOption = (
  readPrivacyOptions: CustomFieldPrivacyOptions[],
) => {
  if (!readPrivacyOptions) {
    return ReadOptions.everyone
  }

  if (
    readPrivacyOptions.includes(CustomFieldPrivacyOptions.OWN) &&
    readPrivacyOptions.includes(CustomFieldPrivacyOptions.ADMIN)
  ) {
    return ReadOptions.own
  }

  if (readPrivacyOptions.includes(CustomFieldPrivacyOptions.ADMIN)) {
    return ReadOptions.admin
  }

  return ReadOptions.everyone
}

export const getWritePermissionOption = (
  writePrivacyOptions: CustomFieldPrivacyOptions[],
) => {
  if (!writePrivacyOptions) {
    return WriteOptions.admin
  }

  if (
    writePrivacyOptions.includes(CustomFieldPrivacyOptions.OWN) &&
    writePrivacyOptions.includes(CustomFieldPrivacyOptions.ADMIN)
  ) {
    return WriteOptions.own
  }

  if (writePrivacyOptions.includes(CustomFieldPrivacyOptions.ADMIN)) {
    return WriteOptions.admin
  }

  return WriteOptions.admin
}

export const setFieldSettings = (
  key: string,
  value: string,
  settings: CustomFieldSettings[],
) => {
  const settingsWithoutKey = settings.filter(setting => setting.key !== key)
  const newEntry: CustomFieldSettings = { key, value: JSON.stringify(value) }

  return [...settingsWithoutKey, newEntry]
}

export const slugify = (text: string) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/_+/g, '-')
    .replace(/^-/, '')
    .replace(/-$/, '')
}

export const slugifyFieldKey = (text: string) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/[^a-zA-Z_]+/g, '')
    .replace(/_+/g, '_')
    .replace(/^_/, '')
    .replace(/_$/, '')
}

export const convertToSelectOptions = (
  options: ColoredLabel[],
): SelectOption[] => {
  const selectOptions = options.map(({ id, name, colorHex }) => {
    const selectOption: SelectOption = {
      id,
      value: name,
      color: colorHex,
    }

    return selectOption
  })

  return selectOptions
}

export const convertToColoredLabel = (
  options: SelectOption[],
): ColoredLabel[] => {
  const coloredLabels = options.map(({ id, value, color }) => {
    const coloredLabel: ColoredLabel = {
      id: id ?? value,
      name: value,
      colorHex: color,
    }

    return coloredLabel
  })

  return coloredLabels
}

export const getColoredLabels = (field: CustomFieldSchema): ColoredLabel[] => {
  const selectOptions =
    getFieldSetting<SelectOption[]>(field, 'selectOptions') ?? []

  if (selectOptions.length > 0) {
    return convertToColoredLabel(selectOptions)
  }

  // If select options is not defined, fall back to enumValidator
  try {
    const fieldValidators = field?.validators ?? field?.items?.validators
    if (!fieldValidators) {
      return []
    }

    const enumValidator = fieldValidators.find(
      validator => validator.validation === CustomFieldValidators.enum,
    )

    if (!enumValidator) {
      return []
    }

    const enumOptions = JSON.parse(enumValidator.value) as string[]

    if (enumOptions?.length) {
      const colorStyles = colorStylesFactory(document)
      const options = enumOptions.map<ColoredLabel>((option, index) => ({
        id: option,
        name: option,
        colorHex: randomColor(colorStyles, index),
      }))

      return options
    }

    return []
  } catch (error) {
    logger.error('Could not parse enumValidator options')
    return []
  }
}

export const getValidators = (
  options: ColoredLabel[],
): CustomFieldValidatorInput[] => {
  if (!options) {
    return []
  }

  const value = options.map(({ id }) => id)
  const validators: CustomFieldValidatorInput[] = [
    {
      validation: CustomFieldValidators.enum,
      value: JSON.stringify(value),
    },
  ]

  return validators
}

export const getFieldItems = (
  subtype: CustomFieldSubtype,
  items: BaseCustomFieldSchema,
  options: ColoredLabel[],
): BaseCustomFieldSchema => {
  if (!items) {
    return null
  }

  const { key, name, type, typeOptions, validators } = items

  if (subtype !== CustomFieldSubtype.MULTISELECT) {
    const newItems: BaseCustomFieldSchema = {
      key,
      name,
      type,
      typeOptions,
      validators,
    }
    return newItems
  }

  if (!options) {
    const newItems: BaseCustomFieldSchema = {
      key,
      name,
      type,
      typeOptions,
      validators,
    }
    return newItems
  }

  const newValidators = getValidators(options)
  const newItems: BaseCustomFieldSchema = {
    key,
    name,
    type,
    validators: newValidators,
  }

  return newItems
}
