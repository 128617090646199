import { unstable_useBlocker as useBlocker } from 'react-router'

import { useFirstRenderContext } from '@tribeplatform/react-components/common/ssr'

import { AppInitialProps } from '../../@types/app.types.js'

export const useHardRefresh = (
  pathPrefixes: string[],
  { subfolder }: AppInitialProps['networkPath'],
): void => {
  const { firstRenderPath } = useFirstRenderContext()
  useBlocker(({ nextLocation }) => {
    const matchesPrefix = pathPrefixes.some(prefix => {
      return nextLocation.pathname.startsWith(prefix)
    })

    const hasChangedClientSide = nextLocation.pathname !== firstRenderPath

    if (matchesPrefix && hasChangedClientSide) {
      window.location.href = `${subfolder}${nextLocation.pathname}${nextLocation.search}${nextLocation.hash}`
      return true
    }
    return false
  })
}
