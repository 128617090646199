import { Multiselect } from '../Multiselect/index.js'
import { FormControlLayout } from './FormControlLayout.js'
import type {
  FormControlMultiselectProps,
  MultiSelectItemWithPayload,
} from './types.js'
import { extractLayoutProps } from './utils.js'

export const FormControlMultiselect = ({
  onChange,
  placeholder,
  value = [],
  options,
  labels = {},
  ...rest
}: FormControlMultiselectProps) => {
  const { layoutProps, inputProps } = extractLayoutProps(rest)

  const isItemWithPayload = (
    item: FormControlMultiselectProps['value'][number],
  ): item is MultiSelectItemWithPayload => {
    return item && typeof item === 'object'
  }

  return (
    <FormControlLayout {...layoutProps}>
      <Multiselect
        {...inputProps}
        onChange={onChange}
        value={
          value?.map(item => (isItemWithPayload(item) ? item.value : item)) ||
          []
        }
        options={options}
      >
        <Multiselect.Button
          placeholder={placeholder}
          hideInput={value?.length > 0}
        >
          {value?.map((item, index) => (
            <Multiselect.SelectedItem
              key={(isItemWithPayload(item) && item.id) || index}
              variant={
                isItemWithPayload(item) && item?.invalid
                  ? 'negative'
                  : 'neutral'
              }
              value={isItemWithPayload(item) ? item.value : item}
              index={index}
            >
              {isItemWithPayload(item) ? item.value : labels[item] ?? item}
            </Multiselect.SelectedItem>
          ))}
        </Multiselect.Button>
        <Multiselect.Items>
          {options.map((item, index) => (
            <Multiselect.Item
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              value={item}
              index={index}
            >
              {labels[item] ?? item}
            </Multiselect.Item>
          ))}
        </Multiselect.Items>
      </Multiselect>
    </FormControlLayout>
  )
}
