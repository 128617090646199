import { clsx } from 'clsx'

import { ContentRenderer } from '../Components/ContentRenderer.js'
import { MediaRenderer } from '../Components/MediaRenderer.js'
import { type HeroBannerProps } from '../types.js'
import {
  getContentSpacingClass,
  getMediaAspectRatioClass,
} from '../utils/utils.js'

interface Props extends HeroBannerProps {
  reverse?: boolean
}
export const TopBottomLayout = ({
  contentSpacing,
  mediaAspectRatio,
  icon,
  eyebrow,
  heading,
  description,
  textColor,
  textAlignment,
  mediaType,
  image,
  video,
  mediaTint,
  backgroundColor,
  reverse,
  actionType,
  buttonActions,
  searchAction,
}: Props) => {
  const spacingClass = getContentSpacingClass(contentSpacing)
  const mediaAspectRatioClass = getMediaAspectRatioClass(mediaAspectRatio)

  return (
    <div
      className={clsx(
        'flex rounded-base overflow-hidden shadow-card',
        'screen-rounded-none',
        'border-base border-card screen-border-x-0',
        reverse ? 'flex-col' : 'flex-col-reverse',
      )}
      style={{ backgroundColor }}
    >
      <ContentRenderer
        actionType={actionType}
        buttonActions={buttonActions}
        searchAction={searchAction}
        textColor={textColor}
        textAlignment={textAlignment}
        icon={icon}
        eyebrow={eyebrow}
        heading={heading}
        description={description}
        className={clsx('px-6 @3xl:px-10 @5xl:px-16', spacingClass)}
      />
      <div className={clsx('relative', mediaAspectRatioClass)}>
        <MediaRenderer
          mediaType={mediaType}
          image={image}
          video={video}
          mediaTint={mediaTint}
          textColor={textColor}
        />
      </div>
    </div>
  )
}
