import { ComponentPropsWithoutRef, ReactNode } from 'react'

import { Switch } from '@headlessui/react'
import { VariantProps } from 'class-variance-authority'

import { useControllableState } from '../hooks/useControllableState.js'
import { Text } from '../Text/index.js'
import { toggleStyles, toggleThumbStyles } from './Toggle.styles.js'

export type ToggleProps = Omit<
  ComponentPropsWithoutRef<'input'>,
  | 'title'
  | 'size'
  | 'onChange'
  | 'checked'
  | 'defaultChecked'
  | 'title'
  | 'name'
  | 'description'
> &
  Omit<VariantProps<typeof toggleStyles>, 'checked'> & {
    checked?: boolean
    onChange?: (value: boolean) => void
    defaultChecked?: boolean
    title?: ReactNode
    name?: string
    description?: ReactNode
  }
/**
 * Toggles (also known as “switches”) is a UI control that has two mutually-exclusive states, such as ON and OFF.
 * The design and functionality of this component is based on a physical switch that allows users to turn things ON or OFF.
 * Toggles are a great visual cue for settings, especially on mobile, because they take less screen estate (in comparison with two radio buttons).
 */
export const Toggle = ({
  title,
  description,
  checked: checkedProp,
  defaultChecked,
  disabled = false,
  onChange: onChangeProp,
  size,
}: ToggleProps) => {
  const [checked, onChange] = useControllableState({
    prop: checkedProp,
    defaultProp: defaultChecked,
    onChange: onChangeProp,
  })

  const element = (
    <Switch
      checked={checked}
      onChange={onChange}
      className={toggleStyles({ size })}
      disabled={disabled}
    >
      <span className="sr-only">{title}</span>
      <span
        aria-hidden="true"
        className={toggleThumbStyles({ checked, size, disabled })}
      />
    </Switch>
  )

  if (title || description) {
    return (
      <Switch.Group as="div" className="flex justify-between gap-3">
        <div className="min-w-0 flex flex-col">
          <Switch.Label
            as={Text}
            variant="bodyMd"
            fontWeight="medium"
            className="text-content text-content-subdued"
            breakWords
          >
            {title}
          </Switch.Label>
          <Switch.Description
            as={Text}
            variant="labelSm"
            className="text-content-subdued"
            breakWords
          >
            {description}
          </Switch.Description>
        </div>
        <div className="shrink-0">{element}</div>
      </Switch.Group>
    )
  }

  return element
}
