import { ComponentProps, forwardRef } from 'react'

import { clsx } from 'clsx'

import { Icon } from '../Icon/index.js'
import type { IconComponentType } from '../Icon/index.js'
import { SvgIcon } from '../Icon/SvgIcon.js'
import {
  inputAriaExpandedStyles,
  inputIconStyles,
  inputStyles,
} from '../Input/Input.styles.js'
import { HTMLTribeProps } from '../types/index.js'
import { useFloatingContext } from '../utils/floating/index.js'
import { useMultiselect } from './MultiselectContext.js'
import { MultiselectInputBadge } from './MultiselectInputBadge.js'

export type MultiselectButtonProps = ComponentProps<'button'> & {
  hideInput?: boolean
  dense?: boolean
  leadingIcon?: IconComponentType
}

export const MultiselectButton = forwardRef<
  HTMLButtonElement,
  MultiselectButtonProps
>((props, ref) => {
  const {
    children,
    className,
    placeholder,
    dense = false,
    leadingIcon,
    hideInput = false,
    ...rest
  } = props
  const {
    inputValue,
    searchable,
    disabled,
    invalid,
    isOpen,
    getComboboxProps,
    getInputProps,
    getDropdownProps,
    getToggleButtonProps,
    setSelectedItems,
    selectedItems,
    addSelectedItem,
    reset,
    onCreateItem,
  } = useMultiselect()
  const { reference } = useFloatingContext()

  // TODO support compact size
  const size = 'default'

  const onKeyDown = event => {
    if (event.key === 'Backspace' && !inputValue) {
      setSelectedItems(selectedItems.slice(0, selectedItems.length - 1))
      reset()
    }
    if (event.key === 'Escape') {
      event.stopPropagation()
    }
  }

  const onPaste = event => {
    // if paste detected and current input is empty, create items without confirmation
    if (!onCreateItem || !!inputValue) {
      return
    }
    const content: string = event?.clipboardData?.getData('Text')
    if (!content) {
      return
    }

    const items = content
      .split(/[\n,]+/)
      .map(it => it.trim())
      .filter(it => !!it)
    const uniqueItems = [...new Set(items)]

    uniqueItems?.forEach(item => {
      if (item && !selectedItems.includes(item)) {
        addSelectedItem(item)
        onCreateItem(item)
      }
    })
    event.preventDefault()
  }

  return (
    <div ref={reference}>
      <div
        className={clsx(
          'group relative',
          'cursor-default',
          inputStyles({
            size,
            invalid,
            disabled,
          }),
          inputAriaExpandedStyles({ invalid }),
          !dense && 'ps-3 pe-10',
          className,
        )}
        {...getComboboxProps(getToggleButtonProps({ disabled, ref, ...rest }))}
      >
        <span className="block truncate">
          <div className="flex flex-wrap -ms-2 -mt-2">
            {leadingIcon && (
              <Icon
                size="lg"
                className={clsx('ms-2 mt-2', !!children && 'me-1')}
              >
                {leadingIcon}
              </Icon>
            )}
            {children}
            <input
              className={clsx(
                'focus:outline-none bg-transparent flex-1 ms-2 mt-2',
                'text-md',
                'placeholder:text-content-disabled placeholder:text-label-md',
                !searchable && ' caret-transparent',
                hideInput && !isOpen && 'w-0 h-0 invisible',
                hideInput && isOpen && !searchable && 'w-0 h-0 invisible',
              )}
              {...getInputProps({
                ...getDropdownProps({
                  preventKeyAction: isOpen,
                }),
                placeholder,
                disabled,
                onKeyDown,
                onPaste,
              })}
              onClick={e => {
                if (hideInput) {
                  e.stopPropagation()
                }
              }}
              readOnly={!searchable}
            />
          </div>
        </span>
        {!dense && (
          <span
            className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none"
            aria-label="toggle menu"
          >
            <SvgIcon
              className={inputIconStyles({ size })}
              aria-hidden="true"
              name="selector"
            />
          </span>
        )}
      </div>
    </div>
  )
})

export type MultiselectSelectedItemProps = HTMLTribeProps<'div'> & {
  value: unknown
  index: number
  variant?: 'neutral' | 'negative'
}

export const MultiselectSelectedItem = ({
  value,
  variant = 'neutral',
  index,
  children,
  className,
  ...rest
}: MultiselectSelectedItemProps) => {
  const { removeSelectedItem, getSelectedItemProps, disabled } =
    useMultiselect()

  return (
    <MultiselectInputBadge
      onRemove={() => removeSelectedItem(value)}
      disabled={disabled}
      variant={variant}
      className={clsx('ms-2 mt-2 truncate', className)}
      {...rest}
      {...getSelectedItemProps({ selectedItem: value, index })}
    >
      {children}
    </MultiselectInputBadge>
  )
}
