import { ReactNode, useMemo } from 'react'

import { getDomainInfo } from '@tribeplatform/react-components/common/utils'
import {
  getIconSocialSpriteUrl,
  getIconSpriteUrl,
  getIconPickerSpriteUrl,
} from '@tribeplatform/react-components/Picker'
import { useAuthToken } from '@tribeplatform/react-sdk/hooks'
import {
  ColorSchemeProvider,
  ThemeProvider,
  calculateColorSchemeMode,
  convertNetworkToAppearance,
} from '@tribeplatform/react-ui-kit/ThemeProvider'

import { AppInitialProps } from '../../@types/index.js'

export type WebThemeProviderProps = {
  children?: ReactNode
  pageProps: AppInitialProps
}

/**
 *
 */
export const WebThemeProvider = ({
  children,
  pageProps,
}: WebThemeProviderProps) => {
  const {
    data: { network },
  } = useAuthToken()
  const { subfolder } = getDomainInfo(network)

  const appearance = useMemo(() => {
    return convertNetworkToAppearance(network)
  }, [network])

  const colorSchemeMode = useMemo(
    () =>
      calculateColorSchemeMode({
        userColorScheme: pageProps.colorScheme,
        networkColorScheme: appearance.networkColorScheme,
      }),
    [appearance, pageProps.colorScheme],
  )

  const assets = useMemo(
    () => ({
      iconSpriteUrl: getIconSpriteUrl(appearance.style.icons, {
        subfolder,
      }),
      iconSocialSpriteUrl: getIconSocialSpriteUrl({ subfolder }),
      iconPickerLineSpriteUrl: getIconPickerSpriteUrl('line', {
        subfolder,
      }),
      iconPickerSolidSpriteUrl: getIconPickerSpriteUrl('solid', {
        subfolder,
      }),
      iconPickerDuocolorSpriteUrl: getIconPickerSpriteUrl('duocolor', {
        subfolder,
      }),
    }),
    [appearance.style.icons, subfolder],
  )

  return (
    <ColorSchemeProvider colorSchemeMode={colorSchemeMode}>
      <ThemeProvider
        theme={appearance.theme}
        typography={appearance.typography}
        style={appearance.style}
        networkColorScheme={appearance.networkColorScheme}
        assets={assets}
      >
        {children}
      </ThemeProvider>
    </ColorSchemeProvider>
  )
}
