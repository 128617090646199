import { Fragment, useEffect, useMemo, useRef, useState } from 'react'

import { useDecision } from '@optimizely/react-sdk'
import { clsx } from 'clsx'

import {
  hasActionPermission,
  hasInputPermission,
} from '@tribeplatform/gql-client/lib'
import {
  CustomFieldSchema,
  CustomFieldType,
  DateTypeOptions,
  Post,
  PostMappingTypeEnum,
  PostStatus,
  PostType,
  PostTypeContext,
  Space,
} from '@tribeplatform/gql-client/types'
import {
  useAddPost,
  useAuthMember,
  useUpdatePost,
} from '@tribeplatform/react-sdk/hooks'
import { Accordion } from '@tribeplatform/react-ui-kit/Accordion'
import { Alert } from '@tribeplatform/react-ui-kit/Alert'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Container } from '@tribeplatform/react-ui-kit/Layout'
import { toast } from '@tribeplatform/react-ui-kit/Toast'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { FeaturesList } from '../common/constants/features.js'
import { logger } from '../common/lib/logger.js'
import {
  convertTzToUtc,
  convertUtcToTz,
  formatUtcDateTime,
} from '../common/utils/date.js'
import { FieldInput } from '../CustomField/FieldInput.js'
import { FieldPrivacyBadges } from '../CustomField/FieldPrivacyBadges.js'
import { CustomFieldSubtype } from '../CustomField/index.js'
import { getFieldSetting, useFieldPermissions } from '../CustomField/utils.js'
import { FormDateTime } from '../Form/FormDateTime/FormDateTime.js'
import { Form, FormRef } from '../Form/index.js'
import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { getTranslatedPostTypeName } from '../Post/utils/postTranslate.js'
import { Prompt } from '../Prompt/index.js'
import { PostSeoOptions } from './PostSeoOptions.js'
import { SimilarPosts } from './SimilarPosts.js'

export const AdminOnlyInfo = () => (
  <Tooltip>
    <Tooltip.Trigger asChild>
      <SvgIcon name="eye-off" inline />
    </Tooltip.Trigger>
    <Tooltip.Panel>
      <T
        id="Post.AdminOnlyInfo"
        defaultMessage="Only admins and moderators can see and update this section."
      />
    </Tooltip.Panel>
  </Tooltip>
)

export const PostForm = ({
  space,
  previewFields,
  post,
  postType,
  onCancel,
  availableSpaces,
  onSubmit: onFormSubmit,
  preview = false,
  highlightedField,
  isPostReady,
}: {
  space?: Space
  previewFields?: CustomFieldSchema[]
  availableSpaces?: Space[]
  post?: Post
  postType: PostType
  onCancel?: () => void
  onSubmit?: (post: Post) => void
  preview?: boolean
  highlightedField?: string
  isPostReady: boolean
}) => {
  const intl = useI18n()
  const { $t } = intl
  const formRef = useRef<FormRef>()
  const isFormReady = useRef(false)

  const isNew = !post?.id
  const fields = previewFields ?? postType?.postFields?.fields
  const similarQueryKey = 'title'

  const [decisionSimilarPosts] = useDecision(FeaturesList.SimilarPosts)

  const { data: member } = useAuthMember()
  const { canUpdateField } = useFieldPermissions(space)

  const [enablePrompt, setEnablePrompt] = useState(false)
  const [similarQuery, setSimilarQuery] = useState(null)
  const [targetSpace, setTargetSpace] = useState(space)

  const isCreatingPost = !post
  const permissions = isCreatingPost
    ? space?.authMemberProps?.permissions
    : post?.authMemberProps?.permissions
  const { actionPermission } = hasActionPermission(
    permissions ?? [],
    isCreatingPost ? 'createPost' : 'updatePost',
  )

  const { authorized: canLock } = hasInputPermission(
    actionPermission?.inputPermissions,
    'input.locked',
  )
  const { authorized: canSetOwnerId } = hasInputPermission(
    actionPermission?.inputPermissions,
    'input.ownerId',
  )
  const { authorized: canSetPublishedAt } = hasInputPermission(
    actionPermission?.inputPermissions,
    'input.publishedAt',
  )
  const { authorized: canSetSlug } = hasInputPermission(
    actionPermission?.inputPermissions,
    'input.slug',
  )
  const { authorized: canSetCustomSeoDetail } = hasInputPermission(
    actionPermission?.inputPermissions,
    'input.customSeoDetail',
  )

  const { mutateAsync: addPost, isLoading: isAdding } = useAddPost({
    fields: 'basic',
  })
  const { mutateAsync: updatePost, isLoading: isUpdating } = useUpdatePost({
    fields: 'basic',
  })

  useEffect(() => {
    setTargetSpace(space)
  }, [space])

  const onSubmit = async (
    {
      fields: postFields,
      customSeoDetail,
      owner,
      publishedAt: rawPublishedAt,
      ...data
    },
    { setError },
  ) => {
    const mappingFields = []
    try {
      const tagNames = data?.tags?.map(tag => tag?.title) || []
      const spaceId = targetSpace?.id
      const ownerId = owner?.id && canSetOwnerId ? owner?.id : undefined

      const publish = typeof data?.publish === 'undefined' ? true : data.publish

      const publishedAt = rawPublishedAt === '' ? null : rawPublishedAt

      delete data?.targetSpace
      delete data?.tags
      delete data?.owner

      if (customSeoDetail) {
        data.customSeoDetail = {
          // b/e requires thumbnailId to be null if the thumbnail is not set
          thumbnailId: customSeoDetail?.thumbnail?.id ?? null,
          noIndex: customSeoDetail?.noIndex,
          description: customSeoDetail?.description,
          title: customSeoDetail?.title,
          canonicalUrl: customSeoDetail?.canonicalUrl || null,
        }
      }

      if (!spaceId) {
        return setError(`targetSpace`, {
          message: $t({
            id: 'Post.Error.NoSpaceSelected',
            defaultMessage: 'Please select a {SPACE}',
          }),
          type: 'validate',
        })
      }

      Object.keys(postFields).forEach(key => {
        let value = postFields[key] ?? null

        const field = fields?.find(field => field.key === key)
        if (field?.type === CustomFieldType.relation) {
          value = value?.id
            ? {
                id: value?.id,
                relation: field?.typeOptions?.relationType,
              }
            : null
        } else if (
          field?.type === CustomFieldType.array &&
          field?.items?.type === CustomFieldType.relation
        ) {
          value =
            value?.map(item => ({
              id: item?.id,
              relation: field?.items?.typeOptions?.relationType,
            })) || []
        } else if (field?.type === CustomFieldType.date) {
          if (value === '') {
            value = null
          }

          if (value) {
            const subtype = getFieldSetting(
              field,
              'subtype',
            ) as CustomFieldSubtype
            if (subtype === CustomFieldSubtype.DATETIME) {
              value = convertTzToUtc(value)
            } else {
              const dateType = field?.typeOptions?.dateType
              if (dateType === DateTypeOptions.datetime) {
                value = formatUtcDateTime(value)
              } else {
                value = formatUtcDateTime(value, 'YYYY-MM-DD')
              }
            }
          }
        } else if (
          field?.type === CustomFieldType.text ||
          field?.type === CustomFieldType.richText
        ) {
          if (value === '') {
            value = null
          }
        } else if (field?.type === CustomFieldType.number) {
          if (value === '') {
            value = null
          } else {
            const numberValue = Number(value)
            if (!Number.isNaN(numberValue)) {
              value = numberValue
            } else {
              value = null
            }
          }
        }

        if (typeof value !== 'undefined') {
          mappingFields.push({
            key,
            value: JSON.stringify(value),
            type: PostMappingTypeEnum.text,
          })
        }
      })
      let newPost

      setEnablePrompt(false)
      if (isNew) {
        newPost = await addPost({
          spaceId,
          input: {
            ...data,
            publish,
            tagNames,
            ownerId,
            postTypeId: postType?.id,
            mappingFields,
            publishedAt,
          },
        })
      } else {
        newPost = await updatePost({
          id: post?.id,
          input: {
            ...data,
            mappingFields,
            tagNames,
            ownerId,
            publish,
            publishedAt,
          },
        })
      }

      if (newPost.status === PostStatus.BLOCKED) {
        toast({
          title: $t({
            id: 'Generics.PendingReview',
            defaultMessage: 'Pending review',
          }),
          description: $t({
            id: 'Post.PendingReview.Description',
            defaultMessage:
              'Your post will be visible to others once it’s been reviewed by a moderator.',
          }),
          status: 'info',
        })
      }

      onFormSubmit(newPost)
    } catch (error) {
      const errors = error?.response?.errors.filter(error => error?.field) || []
      if (errors.length > 0) {
        errors.forEach(error => {
          // If it's a field error, add `fields.` prefix to the field name
          const findErrorFieldName = (errorField: string) => {
            if (fields.find(field => field?.key === errorField)) {
              return `fields.${errorField}`
            }

            const field = mappingFields.find(
              (field, index) => errorField === `mappingFields.${index}.value`,
            )
            if (field) {
              return `fields.${field.key}`
            }

            return errorField
          }

          const errorField = findErrorFieldName(error.field)

          setError(errorField, {
            message: error?.message,
            type: 'validate',
          })
        })
      } else {
        const message = error?.response?.errors?.map(e => e.message)?.join('\n')
        toast({
          title: $t({
            id: 'Generics.Error',
            defaultMessage: 'Error',
          }),
          description:
            message ||
            $t({
              id: 'Generics.SomethingWentWrongTryAgain',
              defaultMessage: 'Something went wrong, please try again.',
            }),
          status: 'error',
        })
      }
    }
  }

  const defaultValues = useMemo(() => {
    return {
      customSeoDetail: canSetCustomSeoDetail
        ? post?.customSeoDetail
        : undefined,
      tags: post?.tags || [],
      locked: canLock ? post?.locked : undefined,
      publishedAt: canSetPublishedAt
        ? post?.publishedAt?.split('.')?.[0]
        : undefined,
      owner: canSetOwnerId ? post?.owner?.member || member : undefined,
      ...(canSetSlug ? { slug: post?.slug } : {}),
      fields:
        post?.fields?.reduce((result, cur) => {
          const postTypeField = fields?.find(field => field.key === cur?.key)

          try {
            let value = JSON.parse(cur.value)

            // If it's a relationship replace the value with the entities
            if (
              (postTypeField?.type === CustomFieldType.relation ||
                postTypeField?.items?.type === CustomFieldType.relation) &&
              cur?.relationEntities
            ) {
              const flatRelationEntities = Object.keys(
                cur?.relationEntities,
              ).reduce(
                (result, key) => [
                  ...result,
                  ...(Array.isArray(cur?.relationEntities?.[key])
                    ? cur?.relationEntities?.[key]
                    : []),
                ],
                [],
              )

              if (Array.isArray(value)) {
                value = flatRelationEntities
              } else {
                value = flatRelationEntities?.[0]
              }
            }

            if (postTypeField.type === CustomFieldType.date && value) {
              const subtype = getFieldSetting(postTypeField, 'subtype')
              if (subtype === CustomFieldSubtype.DATETIME) {
                value = convertUtcToTz(value)
              } else {
                value = formatUtcDateTime(value, 'YYYY-MM-DD')
              }
            }

            result[cur.key] = value
          } catch (e) {
            logger.error('Error parsing member field value', e.message)
          }
          return result
        }, {}) || {},
    }
  }, [
    canLock,
    canSetOwnerId,
    canSetPublishedAt,
    canSetSlug,
    canSetCustomSeoDetail,
    fields,
    member,
    post?.customSeoDetail,
    post?.fields,
    post?.locked,
    post?.owner?.member,
    post?.publishedAt,
    post?.slug,
    post?.tags,
  ])

  useEffect(() => {
    if (formRef.current?.methods && isPostReady && !isFormReady.current) {
      formRef.current.methods.reset(defaultValues)
      isFormReady.current = true
    }
  }, [defaultValues, isPostReady])

  return (
    <Form
      ref={formRef}
      defaultValues={defaultValues}
      onChange={() => {
        if (!preview) {
          setEnablePrompt(true)
        }
      }}
      onSubmit={onSubmit}
    >
      {({ isUploading }) => {
        return (
          <>
            <Prompt
              when={enablePrompt}
              message={$t({
                id: 'Post.Alert.UnsavedChanges',
                defaultMessage:
                  'You have unsaved changes. Are you sure you want to leave this page?',
              })}
            />
            <Container size="md" direction="vertical" spacing="lg">
              {post?.status === PostStatus.DRAFTED && (
                <Alert
                  status="neutral"
                  icon={<SvgIcon name="post" />}
                  title={$t({
                    id: 'Post.Alert.IsDraft',
                    defaultMessage: 'This is a draft post',
                  })}
                  withClose
                >
                  <T
                    id="Post.Alert.IsDraft.Description"
                    defaultMessage="Drafts posts are not visible to other {MEMBERS}"
                  />
                </Alert>
              )}
              {isNew && availableSpaces?.length > 1 && (
                <Form.SpacePicker
                  label={$t({
                    id: 'Post.PickSpace',
                    defaultMessage: 'Post in',
                  })}
                  onChange={setTargetSpace}
                  name="targetSpace"
                  value={targetSpace}
                  options={availableSpaces}
                />
              )}
              {fields?.map((field, index) => {
                if (
                  field?.archived ||
                  !canUpdateField({ field, entity: post })
                ) {
                  return null
                }

                const placeholder = getFieldSetting(field, 'placeholder')
                const helperText = getFieldSetting(field, 'helperText')
                const isSimilarPostField = field?.key === similarQueryKey

                const similarPosts =
                  decisionSimilarPosts.enabled &&
                  isSimilarPostField &&
                  !post?.id ? (
                    <SimilarPosts
                      query={similarQuery || null}
                      spaceId={targetSpace?.id}
                    />
                  ) : null

                return (
                  <Fragment key={field.key}>
                    <div
                      id={field.key}
                      className={clsx(
                        highlightedField === field?.key &&
                          'ring rounded-sm ring-offset-8 ring-offset-surface ring-customizer-blue relative z-10 bg-surface',
                      )}
                    >
                      <FieldInput
                        name={`fields.${field.key}`}
                        field={field}
                        label={
                          <div className="flex space-s-1 items-center">
                            <span>
                              {getTranslatedPostTypeName(intl, field.name)}
                            </span>
                            <FieldPrivacyBadges
                              field={field}
                              key={field?.key}
                            />
                          </div>
                        }
                        helperText={helperText}
                        placeholder={placeholder}
                        autoFocus={index === 0 && !preview}
                        onChange={
                          isSimilarPostField
                            ? value => setSimilarQuery(value)
                            : null
                        }
                      />
                    </div>
                    {similarPosts}
                  </Fragment>
                )
              })}
              {postType.context === PostTypeContext.post && !preview && (
                <Form.TagPicker
                  label={$t({
                    id: 'Generics.Tags',
                    defaultMessage: '{TAGS_CC}',
                  })}
                  name="tags"
                  creatable
                  placeholder={$t({
                    id: 'AddTags.Placeholder',
                    defaultMessage: 'Add {TAGS}...',
                  })}
                  multiple
                />
              )}
              {canSetCustomSeoDetail &&
                postType.context === PostTypeContext.post &&
                !preview && (
                  <Accordion variant="plus" iconAlign="trailing">
                    <Accordion.Button>
                      <div className="space-s-1">
                        <span>
                          <T
                            id="Post.SeoOptions"
                            defaultMessage="SEO options"
                          />
                        </span>
                        <AdminOnlyInfo />
                      </div>
                    </Accordion.Button>
                    <Accordion.Panel>
                      <PostSeoOptions space={targetSpace} />
                    </Accordion.Panel>
                  </Accordion>
                )}
              {(canLock || canSetOwnerId || canSetPublishedAt) && !preview && (
                <Accordion
                  variant="plus"
                  iconAlign="trailing"
                  defaultOpen={post?.locked}
                >
                  <Accordion.Button>
                    <div className="space-s-1">
                      <span>
                        <T
                          id="Generics.AdvancedOptions"
                          defaultMessage="Advanced options"
                        />
                      </span>
                      <AdminOnlyInfo />
                    </div>
                  </Accordion.Button>
                  <Accordion.Panel>
                    <Container size="md">
                      {canLock && (
                        <Form.Toggle
                          name="locked"
                          label={$t({
                            id: 'Post.Lock',
                            defaultMessage: 'Lock post',
                          })}
                          helperText={$t({
                            id: 'Post.Lock.Helper',
                            defaultMessage:
                              '{MEMBERS} cannot comment or react to locked posts.',
                          })}
                        />
                      )}
                      {canSetOwnerId && (
                        <Form.MemberPicker
                          name="owner"
                          label={$t({
                            id: 'Generics.Author',
                            defaultMessage: 'Author',
                          })}
                          helperText={$t({
                            id: 'Post.Author.Helper',
                            defaultMessage:
                              'You can post on behalf of another {MEMBER}. Please make sure the author is aware of this.',
                          })}
                        />
                      )}
                      {canSetPublishedAt && (
                        <FormDateTime
                          type="datetime-local"
                          name="publishedAt"
                          label={$t({
                            id: 'Generics.PublishedOn',
                            defaultMessage: 'Published on',
                          })}
                          helperText={$t({
                            id: 'Post.PublishTime.Helper',
                            defaultMessage:
                              "Use this field to correct or backdate the post's publication date. Future dates are not allowed.",
                          })}
                          maxDate={new Date()}
                        />
                      )}
                    </Container>
                  </Accordion.Panel>
                </Accordion>
              )}

              <Form.Actions>
                {post?.status !== PostStatus.PUBLISHED ? (
                  <div className="flex">
                    <Button
                      size="lg"
                      type="submit"
                      loading={isAdding || isUpdating}
                      disabled={preview || isUploading}
                    >
                      <T id="Generics.Publish" defaultMessage="Publish" />
                    </Button>
                    {/* <Button
                      size="lg"
                      type="submit"
                      loading={isAdding || isUpdating}
                      className="rounded-e-none"
                      disabled={preview}
                    >
                      <T id="Generics.Publish" defaultMessage="Publish" />
                    </Button>
                    <Dropdown placement="top-end">
                      <Dropdown.ButtonMinimal
                        as="div"
                        className="flex items-stretch h-full"
                        disabled={preview}
                      >
                        <Button
                          size="lg"
                          className="rounded-s-none px-2 border-0 border-l border-actionPrimary-600"
                          disabled={preview}
                        >
                          <ChevronDownIcon className="w-5 h-5" />
                        </Button>
                      </Dropdown.ButtonMinimal>
                      <Dropdown.Items>
                        <Dropdown.Item
                          leadingIcon={<PostIcon />}
                          onClick={() => {
                            handleSubmit(data =>
                              onSubmit(
                                { ...data, ...{ publish: false } },
                                { setError },
                              ),
                            )()
                          }}
                        >
                          <T
                            id="Post.SaveAsDraft"
                            defaultMessage="Save as draft"
                          />
                        </Dropdown.Item>
                        <Dropdown.Item leadingIcon={<ClockIcon />} disabled>
                          <div className="flex space-s-2">
                            <span>
                              <T
                                id="Post.Schedule"
                                defaultMessage="Schedule post"
                              />
                            </span>
                            <Badge size="xs" rounded>
                              <T id="Generics.Soon" defaultMessage="Soon" />
                            </Badge>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Items>
                    </Dropdown> */}
                  </div>
                ) : (
                  <Button
                    size="lg"
                    type="submit"
                    loading={isAdding || isUpdating}
                    disabled={preview || !isFormReady.current || isUploading}
                  >
                    <T id="Generics.Update" defaultMessage="Update" />
                  </Button>
                )}
                {!!onCancel && (
                  <Button
                    size="lg"
                    variant="secondaryNeutral"
                    onClick={() => onCancel()}
                    disabled={preview}
                  >
                    <T id="Generics.Cancel" defaultMessage="Cancel" />
                  </Button>
                )}
              </Form.Actions>
            </Container>
          </>
        )
      }}
    </Form>
  )
}
