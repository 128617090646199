import type { AuthTokenFields } from '@tribeplatform/gql-client/graphql'
import type { AuthToken } from '@tribeplatform/gql-client/types'

import {
  useQuery,
  type UseQueryOptions,
} from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getAuthTokenKey } from '../../utils/keys/authToken.keys.js'
import { useCachedAuthToken } from '../cache/useCachedAuthToken.js'

export const useAuthToken = (options?: {
  useQueryOptions?: UseQueryOptions<AuthToken>
  fields?: AuthTokenFields
}) => {
  const { useQueryOptions, fields = 'default' } = options || {}
  const { client, config, onTokenUpdate } = useTribeClient({
    useBetaClient: true,
  })
  const authTokenKey = getAuthTokenKey({ fields })
  const { networkDomain } = config || {}

  return useQuery<AuthToken>(
    authTokenKey,
    () => client.getTokens({ networkDomain }, fields),
    {
      placeholderData: useCachedAuthToken(),
      onSuccess: data => {
        if (typeof onTokenUpdate === 'function') {
          onTokenUpdate(data)
        }
        if (typeof useQueryOptions?.onSuccess === 'function') {
          useQueryOptions.onSuccess(data)
        }
      },
      ...useQueryOptions,
    },
  )
}
