export const polyfillJsFeatures = async () => {
  const featureStats: Array<{
    name: string
    isMissing: boolean
  }> = [
    {
      name: 'Array.prototype.at',
      isMissing: Array.prototype.at === undefined,
    },
    {
      name: 'Array.prototype.flat',
      isMissing: Array.prototype.flat === undefined,
    },
    {
      name: 'Object.fromEntries',
      isMissing: Object.fromEntries === undefined,
    },
    {
      name: 'String.prototype.matchAll',
      isMissing: String.prototype.matchAll === undefined,
    },
  ]

  const featuresToPolyfill = featureStats
    .filter(feature => feature.isMissing)
    .map(feature => feature.name)

  if (featuresToPolyfill.length > 0) {
    const featuresParam = featuresToPolyfill.join(',')
    try {
      const url = `https://polyfill.io/v3/polyfill.min.js?version=3.111.0&features=${featuresParam}`
      return import(/* @vite-ignore */ url)
    } catch (e) {
      logger.error(`Unable to polyfill missing features: ${featuresParam}`, e)
    }
  }
  return Promise.resolve()
}
