import { clsx } from 'clsx'

import { Space, SpaceMembershipStatus } from '@tribeplatform/gql-client/types'
import { ButtonSize } from '@tribeplatform/react-ui-kit/Button'

import { SpaceLeaveButton } from '../../Space/SpaceLeaveButton.js'
import { SpaceMembershipButton } from '../../Space/SpaceMemberShipButton.js'

interface Props {
  space: Space
  highlighted: boolean
  stretchX: string
  size?: ButtonSize
}
export const JoinButton = ({
  space,
  highlighted,
  stretchX,
  size = 'lg',
}: Props) => {
  const joined =
    space?.authMemberProps?.membershipStatus === SpaceMembershipStatus.joined

  return (
    <>
      <div
        className={clsx(
          'flex space-s-2 text-content-subdued items-center empty:hidden',
          highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
          stretchX,
        )}
        onClick={e => e.stopPropagation()}
      >
        {joined ? (
          <SpaceLeaveButton space={space} size={size} fullWidth />
        ) : (
          <SpaceMembershipButton space={space} size={size} fullWidth />
        )}
      </div>
    </>
  )
}
