import { ReactNode, useEffect } from 'react'

import { useDecision } from '@optimizely/react-sdk'
import { Link, matchPath } from 'react-router-dom'

import { FeaturesList } from '@tribeplatform/react-components/common'
import { T } from '@tribeplatform/react-components/i18n'
import { SlateLayout } from '@tribeplatform/react-components/LayoutFrame'
import { useRouterPath } from '@tribeplatform/react-sdk'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Container } from '@tribeplatform/react-ui-kit/Layout'
import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'
import { convertNetworkToAppearance } from '@tribeplatform/react-ui-kit/ThemeProvider'

import { useLogin } from '../../hooks/useLogin.js'
import { PageLayout } from '../Layout/PageLayout.js'

export const SettingsLayout = ({ children }: { children: ReactNode }) => {
  const pathname = useRouterPath()
  const [decisionConnectedApps] = useDecision(FeaturesList.ConnectedApps)
  const { data: network } = useNetwork()
  const { privateMessagingEnabled } = network?.settings?.privateMessaging || {}
  const appearance = convertNetworkToAppearance(network)

  const { isLoggedIn, showLogin } = useLogin()

  useEffect(() => {
    if (!isLoggedIn) {
      showLogin()
    }
  }, [isLoggedIn, showLogin])

  const items = [
    {
      icon: <SvgIcon className="rtl:-scale-x-100" name="arrow-left" />,
      text: (
        <T defaultMessage="Back to {COMMUNITY}" id="Generics.BackToCommunity" />
      ),
      link: '/',
    },
    {
      icon: <SvgIcon name="key" />,
      text: <T defaultMessage="Account" id="Generics.Account" />,
      link: '/settings/account',
    },
    // { icon: <LockIcon />, text: 'Security', link: '/settings/security' },
    {
      icon: <SvgIcon name="bell" />,
      text: <T defaultMessage="Notifications" id="Generics.Notifications" />,
      link: '/settings/notifications',
    },
  ]

  if (decisionConnectedApps.enabled) {
    items.push({
      text: (
        <T
          defaultMessage="Connected apps"
          id="Settings.ConnectedApps.ConnectedApps"
        />
      ),
      icon: <SvgIcon name="apps" />,
      link: '/settings/connected-apps',
    })
  }

  if (privateMessagingEnabled) {
    items.push({
      text: <T defaultMessage="Messaging" id="Generics.Messaging" />,
      icon: <SvgIcon name="message" />,
      link: '/settings/messaging',
    })
  }

  if (appearance.networkColorScheme.mode === 'user-preference') {
    items.push({
      icon: <SvgIcon name="moon" />,
      text: <T defaultMessage="Appearance" id="Generics.Appearance" />,
      link: '/settings/appearance',
    })
  }

  return (
    <PageLayout layout={SlateLayout.headerNoSidebar}>
      <Container
        size="full"
        padding={{
          vertical: 'md',
          horizontal: { size: 'md', disableOnMobile: true },
        }}
      >
        <Container size="xl" direction="grid">
          <Container size="sm" padding={{ size: 'md', onlyOnMobile: true }}>
            <NavVertical>
              <NavVertical.Group>
                {items.map(item => {
                  const active = matchPath(
                    {
                      path: item.link,
                      end: true,
                      caseSensitive: true,
                    },
                    pathname,
                  )

                  return (
                    <NavVertical.Item
                      key={item.link}
                      active={!!active}
                      as={Link}
                      leadingIcon={item.icon}
                      to={item.link}
                    >
                      {item.text}
                    </NavVertical.Item>
                  )
                })}
              </NavVertical.Group>
            </NavVertical>
          </Container>
          <Container size="md">{children}</Container>
        </Container>
      </Container>
    </PageLayout>
  )
}
