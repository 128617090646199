import { App } from './App.js'
import { RouteGuard } from './RouteGuard.js'

export const AppClient: typeof App = props => {
  return (
    <>
      {/* The client side route guards require a data router to be present.
       * Since the server side router is static and doesn't support some of the
       * API used for client side logic, we are wrapping the app in a separate component
       * for client to inject the guards. The proper solution to this requires to fully migrate
       * to the data compatible version of the StaticRouter which is going to affect our data fetching
       * and rendering paradigms.
       */}
      {/* eslint-disable-next-line react/destructuring-assignment */}
      <RouteGuard pageProps={props.pageProps} />
      <App {...props} />
    </>
  )
}
