import { createIntlCache } from 'react-intl'

import type { I18nObject } from '@tribeplatform/react-components/i18n'
import { createI18n } from '@tribeplatform/react-components/i18n'

import { TranslatedDictionary } from './types.js'

const intlCache = createIntlCache()
const i18nStore: Record<string, I18nObject> = {}

/**
 * If we decide to allow translation customization per community,
 * then we need to extend this method to create a separate
 * cache instance per network:
 *  getIntlCacheForNetwork(networkId: string) => IntlCache
 */
export const I18nStore = (locale: string, messages: TranslatedDictionary) => {
  return createI18n(
    {
      locale,
      messages,
    },
    intlCache,
  )
}

I18nStore.getOrCreate = (locale: string, messages: TranslatedDictionary) => {
  if (!i18nStore[locale]) {
    i18nStore[locale] = I18nStore(locale, messages)
  }
  return i18nStore[locale]
}
