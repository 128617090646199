import chroma from 'chroma-js'
import { DeepPartial } from 'react-hook-form'

import {
  NewTheme,
  Theme as NetworkTheme,
  ThemeColor,
  ThemeToken,
} from '@tribeplatform/gql-client/types'

import {
  convertLegacyThemeColors,
  createColorGroup,
  extendTheme,
  invertThemeColors,
} from './themes.utils.js'
import { ColorGroup, CustomizerTheme, LegacyThemeColors } from './types.js'

const convertFromTokens = (tokens: (ThemeToken | ThemeColor)[]) => {
  const result = {}
  tokens.forEach(token => {
    result[token.key] =
      (token as ThemeToken)?.value || (token as ThemeColor)?.weights
  })
  return result
}

const convertFromColorTokens = (tokens: ThemeColor[]) => {
  const colors = convertFromTokens(tokens)
  Object.keys(colors).forEach(key => {
    colors[key] = convertFromTokens(colors[key])
  })
  return colors
}

export const convertToTokens = (obj: Record<string, string>): ThemeToken[] => {
  return Object.keys(obj)
    .filter(key => !!obj[key])
    .map(key => ({ key, value: obj[key] }))
}

export const convertToColorTokens = (
  colors: LegacyThemeColors,
): ThemeColor[] => {
  return Object.keys(colors).map(key => ({
    key,
    weights: convertToTokens(colors[key]),
  }))
}

export class ThemeConvertor {
  private base: CustomizerTheme

  private theme: DeepPartial<CustomizerTheme>

  private networkOldTheme: NetworkTheme

  private networkTheme: NewTheme

  constructor(options: {
    base: CustomizerTheme
    theme?: DeepPartial<CustomizerTheme>
    networkOldTheme?: NetworkTheme
    networkTheme?: NewTheme
  }) {
    const { base, theme, networkOldTheme, networkTheme } = options
    this.base = base
    this.theme = theme
    this.networkOldTheme = networkOldTheme
    this.networkTheme = networkTheme
  }

  private convertNetworkOldThemeToTheme(): DeepPartial<CustomizerTheme> {
    const { tokens } = this.networkOldTheme
    const { colors: oldColors = [] } = tokens

    const colorKeyMapper = {
      'accent.base': ['actionPrimary'],
      'bg.base': ['surface', 'actionSecondary'],
      'bg.secondary': ['main'],
      'label.primary': ['basicMain', 'basicSurface', 'basicSecondary'],
    }
    const textWhite = createColorGroup('#FFFFFF', false)
    const backgroundWhite = createColorGroup('#FFFFFF', false, true)
    const labelPrimary = createColorGroup('#27282B', false)
    const lightColors: Record<string, ColorGroup> = {
      actionPrimary: createColorGroup('#2D9F6F', false),
      surface: backgroundWhite,
      actionSecondary: backgroundWhite,
      main: createColorGroup('#F4F4F6', false, true),
      basicMain: labelPrimary,
      basicSurface: labelPrimary,
      basicSecondary: labelPrimary,
    }
    oldColors.forEach(({ key, value }) => {
      if (colorKeyMapper[key]) {
        const hex = value?.replace(/\s+/g, '')
        if (!chroma.valid(hex)) {
          return
        }
        const mappedColor = createColorGroup(
          hex,
          false,
          !['label.primary', 'accent.base'].includes(key),
        )
        colorKeyMapper[key].forEach(mappedKey => {
          lightColors[mappedKey] = mappedColor
        })
      }
    })
    lightColors.actionAccent = createColorGroup(
      lightColors.actionPrimary[600],
      false,
    )
    lightColors.actionAccentHover = createColorGroup(
      lightColors.actionPrimary[400],
      false,
    )
    lightColors.basicPrimary = textWhite

    return {
      colors: { light: lightColors },
    }
  }

  private convertNetworkThemeToTheme(): DeepPartial<CustomizerTheme> {
    const { colors, typography: typographyTokens } = this.networkTheme
    const { light: lightTokens, dark: darkTokens } = colors

    const legacyLight = convertFromColorTokens(lightTokens) as LegacyThemeColors
    const legacyDark = convertFromColorTokens(darkTokens) as LegacyThemeColors

    return {
      colors: {
        light: legacyLight,
        dark: legacyDark,
      },
      newColors: {
        light1: convertLegacyThemeColors(legacyLight),
        dark1: convertLegacyThemeColors(invertThemeColors(legacyDark)),
      },
      typography: convertFromTokens(
        typographyTokens.map(token => ({
          ...token,
          value: JSON.parse(token.value),
        })),
      ),
    }
  }

  toTheme(): CustomizerTheme {
    let result: CustomizerTheme = { ...this.base }

    if (this.networkOldTheme) {
      result = extendTheme(result, this.convertNetworkOldThemeToTheme())
    }
    if (this.networkTheme) {
      result = extendTheme(result, this.convertNetworkThemeToTheme())
    }
    if (this.theme) {
      result = extendTheme(result, this.theme)
    }

    const light = convertLegacyThemeColors(result.colors.light)
    const dark = convertLegacyThemeColors(invertThemeColors(result.colors.dark))
    result.newColors = {
      id: 'legacy',
      name: 'Legacy',
      light1: light,
      light2: light,
      light3: light,
      dark1: dark,
      dark2: dark,
      palette: {
        primary: light['action-primary'],
        accent: light.link,
        neutral: light.surface,
      },
    }
    return result
  }

  toNetworkTheme(): NewTheme {
    // ignore newColors
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { newColors, ...theme } = this.toTheme()
    return {
      ...theme,
      colors: {
        light: convertToColorTokens(theme.colors.light),
        dark: convertToColorTokens(theme.colors.dark),
      },
      typography: convertToTokens(
        Object.keys(theme.typography).reduce(
          (preValue, key) => ({
            ...preValue,
            [key]: JSON.stringify(theme.typography[key]),
          }),
          {},
        ),
      ),
    }
  }
}
