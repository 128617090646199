import { SystemSpaceSlug, useSpaceUtil } from '@tribeplatform/react-sdk/hooks'

import { LoginButton } from '../Actions/LoginButton.js'
import { SignUpButton } from '../Actions/SignUpButton.js'

export const SignInActions = () => {
  const { generatePath, hasViewPermission } = useSpaceUtil()
  const canSignUp = hasViewPermission(SystemSpaceSlug.SIGNUP)
  const canLogIn = hasViewPermission(SystemSpaceSlug.LOGIN)

  return (
    <>
      {canSignUp && (
        <div className="h-full flex justify-center items-center shrink-0">
          <SignUpButton href={generatePath(SystemSpaceSlug.SIGNUP)} />
        </div>
      )}
      {canLogIn && (
        <div className="h-full flex justify-center items-center shrink-0">
          <LoginButton href={generatePath(SystemSpaceSlug.LOGIN)} />
        </div>
      )}
    </>
  )
}
