import type { AppInitialProps } from '../@types/app.types.js'
import { useHardRefresh } from './hooks/useHardRefresh.js'
import { usePrivateNetworkGuard } from './hooks/usePrivateNetworkGuard.js'
import { useRedirectAuthUser } from './hooks/useRedirectAuthUser.js'
import { useRedirectUnconfirmedUser } from './hooks/useRedirectUnconfirmedUser.js'

type RouteGuardProps = {
  pageProps: AppInitialProps
}

export const RouteGuard = (props: RouteGuardProps) => {
  const { pageProps } = props
  const { networkPublicInfo, role, member } = pageProps.authToken || {}

  useHardRefresh(['/auth/'], pageProps.networkPath)
  usePrivateNetworkGuard(role?.type, networkPublicInfo?.visibility)
  useRedirectAuthUser(role?.type)
  useRedirectUnconfirmedUser(member, role)
  return <></>
}
