export const FeaturesList = {
  BetaToggle: 'beta_toggle',
  BlockCopyPaste: 'block_copy_paste',
  CommandBar: 'command_bar',
  ConnectedApps: 'connected_apps',
  CreateSpaceTemplate: 'create_space_template',
  DynamicBlockSettings: 'dynamic_block_settings',
  IconPicker: 'icon_picker',
  IntercomFederatedSearch: 'intercom_federated_search',
  PostMassActions: 'post_mass_actions',
  Saml: 'saml',
  SearchPageTagFilter: 'search_page_tag_filter',
  SimilarPosts: 'similar_posts',
  SlateBuilder: 'slate_builder',
  SmartSearch: 'smart_search',
  SpaceTemplateManagement: 'space_template_management',
  TotalsAnalytics: 'totals_analytics',
  TranslateButton: 'translate_button',
  ViewPostOnVisible: 'view_post_on_visible',
  PostTypes: 'post_types',
  ReferralProgram: 'referral_program',
  // Cannot create new feature flags on optimizely anymore unless
  // migrate to the Feature Experimentation product
  // https://docs.developers.optimizely.com/feature-experimentation/docs/introduction
  // Since we have decided to get rid of optimizely, we will put section block behind
  // existing 'extended_customizer' flag, which is not being used.
  SectionBlock: 'extended_customizer',
}
