import type { CollectionFields } from '@tribeplatform/gql-client/graphql'
import type {
  Collection,
  QueryCollectionsArgs,
} from '@tribeplatform/gql-client/types'

import {
  useQuery,
  type UseQueryOptions,
} from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getCollectionsKey } from '../../utils/keys/collection.key.js'

export const useCollections = (options?: {
  useQueryOptions?: UseQueryOptions<Collection[]>
  variables?: QueryCollectionsArgs
  fields?: CollectionFields
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options || {}
  const { client } = useTribeClient({ useBetaClient: true })
  const collectionsKey = getCollectionsKey({ variables, fields })

  return useQuery<Collection[]>(
    collectionsKey,
    () => client?.collections.list({ ...variables }, fields),
    useQueryOptions,
  )
}
