import { lazy, ReactNode, Suspense, useEffect, useRef } from 'react'

import { useDecision } from '@optimizely/react-sdk'
import { NavigationType, useNavigationType } from 'react-router-dom'

import { CookieContextProvider } from '@tribeplatform/react-components/Apps'
import { FeaturesList } from '@tribeplatform/react-components/common'
import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import { useRouterPath } from '@tribeplatform/react-sdk'
import { useAuthToken } from '@tribeplatform/react-sdk/hooks'
import { SlateKitProvider } from '@tribeplatform/react-slate-kit/components'
import { useSlateKit } from '@tribeplatform/react-slate-kit/hooks'

import type { AppInitialProps } from '../@types/index.js'
import { Wrapper } from './components/Admin/Editor/SlateWrappers/index.js'
import { AccountVerificationAlert } from './components/Auth/components/AccountVerificationAlert.js'
import { RenderError } from './components/Error/Error.js'
import { ErrorFallback } from './components/Error/ErrorBoundry.js'
import { InteractionComponents } from './interactions/InteractionComponents.js'
import { getRegisteredBlocks } from './registered-blocks.js'

function ScrollToTop() {
  const pathname = useRouterPath()
  const navigationType = useNavigationType()
  const prevPathname = useRef(pathname)

  useEffect(() => {
    if (
      navigationType !== NavigationType.Pop &&
      pathname !== prevPathname.current
    ) {
      window.scrollTo(0, 0)
    }
    prevPathname.current = pathname
  }, [navigationType, pathname])

  return null
}

const CookieConsent = lazy(() =>
  import('@tribeplatform/react-components/Apps').then(m => ({
    default: m.CookieConsent,
  })),
)

export const SlateApp = ({
  pageProps,
  children,
}: {
  pageProps: AppInitialProps
  children: ReactNode
}) => {
  const registeredBlocks = useStaticIntl(getRegisteredBlocks)
  const [decisionDynamicBlockSettings] = useDecision(
    FeaturesList.DynamicBlockSettings,
  )
  const slateKit = useSlateKit({
    blocks: registeredBlocks,
    blockWrapper: Wrapper,
    dynamicBlockSettings: decisionDynamicBlockSettings.enabled,
  })

  const { data: { network, member } = pageProps.authToken } = useAuthToken({
    useQueryOptions: {
      // this is here to prevent the whole page from re-rendering
      notifyOnChangeProps: ['data'],
    },
  })

  return (
    <SlateKitProvider
      kit={slateKit}
      context={{
        network,
        actorId: member?.id,
        space: null,
        networkId: network?.id,
      }}
      onError={({ error, ...extras }) => {
        const slateError = new RenderError({
          message: extras,
          error,
          name: 'SlateError',
        })
        logger.error(slateError)
      }}
      ErrorFallback={ErrorFallback}
    >
      <ScrollToTop />
      <InteractionComponents />
      <AccountVerificationAlert />
      {children}
      <Suspense fallback={<></>}>
        <CookieContextProvider>
          <CookieConsent />
        </CookieContextProvider>
      </Suspense>
    </SlateKitProvider>
  )
}
