import { useEffect, useState } from 'react'

import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { OTSM } from '../../@types/index.js'

export const useOneTimeServerMessage = (otsm: OTSM) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted && otsm?.description) {
      toast({
        description: otsm.description,
        title: otsm.title || 'Error',
        status: otsm.status || 'error',
        duration: Infinity,
      })
      // show toast just once
      setIsMounted(false)
    }
  }, [isMounted, otsm])
}
