import { ReactNode, useRef } from 'react'

import {
  OptimizelyProvider,
  createInstance,
  logOnlyEventDispatcher,
} from '@optimizely/react-sdk'

import { getDomainInfo } from '@tribeplatform/react-components/common/utils'
import { useAuthToken } from '@tribeplatform/react-sdk/hooks'

import { RuntimeConfigs } from '../../../configs/runtime.js'

type OptimizelyProps = {
  children: ReactNode
  datafile: object | string
}

export const Optimizely = ({ children, datafile }: OptimizelyProps) => {
  const isServer = typeof window === 'undefined'
  const { data: authToken } = useAuthToken()
  const { subfolder } = getDomainInfo(authToken?.networkPublicInfo)

  const { current: optimizely } = useRef(
    createInstance({
      datafile,
      sdkKey: RuntimeConfigs.OPTIMIZELY_SDK_KEY,
      logLevel: 'error',
      datafileOptions: {
        autoUpdate: false,
        urlTemplate: `${subfolder}/api/analytics/ff-datafiles/%s`,
        updateInterval: 1000 * 60 * 30,
      },

      eventDispatcher:
        !isServer &&
        RuntimeConfigs.NODE_ENV === 'development' &&
        logOnlyEventDispatcher,
    }),
  )

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      isServerSide={isServer}
      user={{
        id: authToken?.member?.id || '',
        attributes: {
          // Passing null/undefined values to these keys will cause the feature flag break
          email: String(authToken?.member?.email),
          username: String(authToken?.member?.username),
          name: String(authToken?.member?.name),
          roleType: String(authToken?.member?.role?.type),
          communityId: String(authToken?.networkPublicInfo?.id),
        },
      }}
    >
      {children}
    </OptimizelyProvider>
  )
}
