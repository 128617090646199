import locales from 'dayjs/locale.json'

import { dayjs } from '@tribeplatform/react-components/common/lib'

const localeModules = import.meta.glob(
  '../../../../node_modules/dayjs/esm/locale/{de,en,es,fr,hi,ja,ko,pt,tr,zh,nl,pl,ro,it,da,fi,nb,sv}?(-+([a-z]|[A-Z])).mjs',
)

const normalizeLocale = (localeString: string): string => {
  const lowerCasedLocale = localeString.toLowerCase()
  // Special case to map zh-hans & zh-hant to respective dayjs supported locales
  if (lowerCasedLocale === 'zh-hans') {
    return 'zh-cn'
  }

  if (lowerCasedLocale === 'zh-hant') {
    return 'zh-tw'
  }

  return lowerCasedLocale
}

const validateLocale = (localeString: string): string => {
  const normalizedLocale = normalizeLocale(localeString)
  const [localeLangCode] = normalizedLocale.split('-')

  // first try to find with normalized locale then try language code
  const locale =
    locales.find(locale => locale.key === normalizedLocale) ||
    locales.find(locale => locale.key === localeLangCode)

  if (locale) {
    return locale.key
  }
  return 'en'
}

export const localizeDayJs = async (locale: string): Promise<void> => {
  const dayJsLocale = validateLocale(locale)
  for (const path in localeModules) {
    if (path.endsWith(`/${dayJsLocale}.mjs`)) {
      // It'll match only once and we want to block the loop
      // eslint-disable-next-line no-await-in-loop
      const t = (await localeModules[path]()) as {
        default: ILocale
      }
      dayjs.locale(dayJsLocale, t.default)
      return
    }
  }
}
