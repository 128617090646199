import { useRouter } from '@tribeplatform/react-sdk'
import { useSearch } from '@tribeplatform/react-sdk/hooks'
import { getSearchKey } from '@tribeplatform/react-sdk/utils/keys'
import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { List } from '@tribeplatform/react-ui-kit/Layout'

import { useDebounce } from '../common/hooks/useDebounce.js'
import { T } from '../i18n/components/T.js'
import { getSearchItemLink } from '../Search/utils.js'
import { cleanSearchQuery } from '../utils/search.js'

export const SimilarPosts = ({ query: dirtyQuery, spaceId = null }) => {
  const query = cleanSearchQuery(dirtyQuery)

  const debouncedQuery = useDebounce(query, 100)
  const { Link } = useRouter()

  const generatedQuery = `${query} +for:post ${spaceId ? `+in:${spaceId}` : ''}`

  const { data: searchData } = useSearch({
    variables: {
      input: {
        query: generatedQuery,
      },
    },
    fields: { in: 'basic' },
    useQueryOptions: {
      queryKey: getSearchKey({
        variables: {
          input: { query: generatedQuery },
        },
      }),
      keepPreviousData: true,
      enabled: !!debouncedQuery,
    },
  })

  if (!query) {
    return null
  }

  const posts = searchData?.hits?.[0]?.hits || []
  if (!posts?.length) {
    return null
  }
  return (
    <FormControl>
      <FormControl.Label>
        <T id="Post.SimilarPosts.SectionTitle" defaultMessage="Similar posts" />
      </FormControl.Label>
      <List className="border border-line-subdued rounded-base" spacing="none">
        {[...posts]?.splice(0, 7).map(post => (
          <List.Item key={post?.entityId}>
            <Link
              className="p-3 space-s-3 border-md flex items-center hover:bg-surface-hovered"
              variant="neutral"
              target="_blank"
              href={getSearchItemLink(post)}
            >
              <SvgIcon className="w-5 h-5" name="post" />
              <span className="flex-1 truncate">{post?.title}</span>
              <SvgIcon className="w-4 h-4" name="link-external" />
            </Link>
          </List.Item>
        ))}
      </List>
    </FormControl>
  )
}
