import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { CONTENT_FIELD_KEY } from '@tribeplatform/react-components/CMS'
import { TRUNCATE_SCROLL_OFFSET } from '@tribeplatform/react-components/CustomField'
import { HtmlContent } from '@tribeplatform/react-components/HtmlContent'
import { useI18n } from '@tribeplatform/react-components/i18n'
import { PostTranslate } from '@tribeplatform/react-components/Post'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { Truncate } from '@tribeplatform/react-ui-kit/Truncate'

import { getPostFieldValue } from '../utils/index.js'

interface Props {
  field: StaticField
  post: Post
  originalPost: Post
  highlighted: boolean
  stretchX: string
  displayStyle: StaticField['settings']['displayStyle']
  truncateSize: StaticField['settings']['truncateSize']
  preview: boolean
  setTranslatedPost: (post: Post) => void
}
export const CardPostContent = ({
  field,
  post,
  originalPost,
  highlighted,
  stretchX,
  displayStyle,
  truncateSize,
  preview,
  setTranslatedPost,
}: Props) => {
  const { $t } = useI18n()
  const { default: defaultContent } = field
  const content =
    getPostFieldValue(post, CONTENT_FIELD_KEY) ||
    (preview ? defaultContent : '')

  return (
    <div
      className={clsx(
        'empty:hidden break-words min-w-0',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      {displayStyle === 'full' ? (
        <article className="prose">
          <HtmlContent
            value={content}
            attachments={post?.attachments}
            embeds={post?.embeds}
            mentions={post?.mentions}
          />
        </article>
      ) : (
        <Truncate
          scrollOffset={TRUNCATE_SCROLL_OFFSET}
          size={truncateSize ?? 'md'}
          expandable={displayStyle === 'expandable'}
          seeMoreText={$t({
            defaultMessage: 'See more',
            id: 'Generics.SeeMore',
          })}
          seeLessText={$t({
            defaultMessage: 'See less',
            id: 'Generics.SeeLess',
          })}
        >
          <article className="prose">
            <HtmlContent
              value={content}
              attachments={post?.attachments}
              embeds={post?.embeds}
              mentions={post?.mentions}
            />
          </article>
        </Truncate>
      )}
      <PostTranslate post={originalPost} onTranslate={setTranslatedPost} />
    </div>
  )
}
