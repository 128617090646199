import { useRouter } from '@tribeplatform/react-sdk'
import { useSpaceUtil } from '@tribeplatform/react-sdk/hooks'
import { Text } from '@tribeplatform/react-ui-kit/Text'

import { TITLE_FIELD_KEY } from '../../CMS/constants.js'
import { PostHiddenIndicator } from '../../Post/PostHiddenIndicator.js'
import { PostLockedIndicator } from '../../Post/PostLockedIndicator.js'
import type { PostContext } from '../../Post/types.js'
import { CustomFieldViewProps, TitleSizes, getTitleVariant } from '../utils.js'

interface Props extends CustomFieldViewProps {
  titleSize?: TitleSizes
  context?: PostContext
}
export const TitleView = ({
  field,
  entity,
  value,
  titleSize = 'md',
  context,
}: Props) => {
  const { generatePostPath } = useSpaceUtil()
  const { Link } = useRouter()
  const isHidden = entity?.isHidden
  const isLocked = entity?.locked

  if (context === 'post') {
    return (
      <Text as="h2" variant={getTitleVariant(titleSize)}>
        {isHidden && <PostHiddenIndicator />}
        {isLocked && <PostLockedIndicator />}
        {value}
      </Text>
    )
  }

  if (field?.key === TITLE_FIELD_KEY) {
    return (
      <Link
        href={entity?.relativeUrl || generatePostPath(entity?.space, entity)}
        onClick={e => e.stopPropagation()}
        className="inline-block"
      >
        <Text
          as="h2"
          variant={getTitleVariant(titleSize)}
          className="line-clamp-3"
        >
          {isHidden && <PostHiddenIndicator />}
          {isLocked && <PostLockedIndicator />}
          {value}
        </Text>
      </Link>
    )
  }

  return (
    <Link
      href={entity?.relativeUrl || generatePostPath(entity?.space, entity)}
      onClick={e => e.stopPropagation()}
      className="inline-block"
    >
      <Text as="h2" variant={getTitleVariant(titleSize)} className="block">
        {value}
      </Text>
    </Link>
  )
}
