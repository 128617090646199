import type { WebLogger } from '../logger/@types/index.js'
import { defaultLogger } from '../logger/client/index.js'

export {}

declare global {
  interface Window {
    logger: WebLogger
  }
}

window.logger = defaultLogger
