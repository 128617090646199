import { Suspense, lazy } from 'react'

import { clsx } from 'clsx'
import { Element } from 'html-react-parser'

import { useFirstRenderContext } from '../common/ssr/FirstRenderProvider.js'
import { Figcaption } from '../Composer/extensions/image/Figcaption.js'
import { ImageAlt } from '../Composer/extensions/image/ImageAlt.js'
import { ImageAlign, ImageSize } from '../Composer/extensions/image/types.js'
import { convertFigureDataAttributesToClassname } from '../Composer/extensions/image/utils.js'
import { AppsEnum } from './@types.js'

const ReactPlayer = lazy(() =>
  import('../utils/react-player.js').then(m => ({ default: m.ReactPlayer })),
)

interface DomVideoProps {
  domNode: Element
  className?: string
}
export const DomVideo = ({ domNode, className }: DomVideoProps) => {
  const { isFirstRender } = useFirstRenderContext()

  return (
    <figure>
      <div
        className={clsx(
          'aspect-auto rounded-base screen-rounded-none overflow-hidden',
          className,
        )}
      >
        {!isFirstRender && (
          <Suspense fallback={<></>}>
            <ReactPlayer
              width="100%"
              height="auto"
              controls
              url={domNode.attribs.src}
            />
          </Suspense>
        )}
      </div>
    </figure>
  )
}

interface DomAudioProps {
  domNode: Element
}
export const DomAudio = ({ domNode }: DomAudioProps) => {
  const { isFirstRender } = useFirstRenderContext()

  return (
    /**
     * ReactPlayer does not allow configuring the inner audio
     * element height, as in setting height to 'revert' on the wrapper
     * will still make the audio/video element to be of size 100%
     * and no other option will force the element assume its natural height.
     * That's why the following className is added to the container with important specificity.
     */
    <figure className="[&_audio]:!h-[revert]">
      {!isFirstRender && (
        <Suspense fallback={<></>}>
          <ReactPlayer
            config={{ file: { forceAudio: true } }}
            width="100%"
            height="revert"
            controls
            url={domNode.attribs.src}
          />
        </Suspense>
      )}
    </figure>
  )
}

export const renderV1Img = (
  domNode: Element,
  { mediaIndex, onClick, context },
) => {
  const app = domNode?.attribs?.['data-app']
  const isGiphy = app === AppsEnum.Giphy
  const fullWidth = !(isGiphy || context === 'compact')

  return (
    <figure>
      <div
        className={clsx(
          'rounded-base screen-rounded-none overflow-hidden',
          'flex items-center ignore-typography',
          fullWidth && 'justify-center bg-surface-subdued',
        )}
        data-slide-index={mediaIndex}
        onClick={onClick}
      >
        <img
          src={domNode.attribs.src}
          className={clsx(
            'cursor-pointer',
            !fullWidth ? 'max-h-48' : 'max-h-120',
          )}
          alt=""
        />
      </div>
    </figure>
  )
}

type TRenderV2Img = (
  /**
   * No proper type is available for figureNode prop and is hard to efficiently narrow.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  figureNode: any,
  opts: {
    mediaIndex: number | undefined
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  },
) => JSX.Element
export const renderV2Img: TRenderV2Img = (
  figureNode,
  { mediaIndex, onClick },
) => {
  const imageNode = figureNode.firstChild
  const { src, title, alt } = imageNode.attribs
  const hasAlt = !!alt

  const figcaptionNode = figureNode.children.find(
    node => node.name === 'figcaption',
  )

  const caption = figcaptionNode?.children[0]?.data
  const hasCaption = !!caption
  const hasOverlayCaption =
    figcaptionNode?.attribs?.['data-as-overlay'] === 'true'

  const figureClassNames = convertFigureDataAttributesToClassname({
    size: figureNode?.attribs?.['data-size'] as ImageSize,
    align: figureNode?.attribs?.['data-align'] as ImageAlign,
    hasCaption: hasCaption && !hasOverlayCaption,
    app: figureNode?.attribs?.['data-app'] as AppsEnum,
  })

  return (
    <figure className={figureClassNames}>
      <div
        data-slide-index={mediaIndex}
        onClick={onClick}
        className={clsx('rounded-base flex items-center ignore-typography')}
      >
        <img
          src={src}
          title={title}
          alt={alt}
          className="cursor-pointer rounded-base"
        />
      </div>
      {hasAlt && (
        <ImageAlt
          description={alt}
          imageId={figureNode?.attribs?.['data-id'] ?? src}
        />
      )}
      {hasCaption && (
        <Figcaption asOverlay={hasOverlayCaption}>{caption}</Figcaption>
      )}
    </figure>
  )
}
